(function () {
    'use strict';

    angular.module('PWAPoCApp').service('deviationModalService', deviationModalService);

    deviationModalService.$inject = ['$kWindow', '$rootScope'];

    function deviationModalService($kWindow, $rootScope) {
        var service = {
            create
        };

        return service;

        function create(options) {
            return $kWindow.open({
                options: {
                    modal: true,
                    title: $rootScope.translate('routeStopCard.deviations.deviation') + options.title || "",
                    movable: false,
                    resizable: false,
                    visible: false,
                    height: '100%',
                    width: '100%'
                },
                templateUrl: 'app/routeStops/modals/deviation-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'DeviationModalController',
                resolve: {
                    orderId: function () {
                        return options.orderId;
                    },
                    routeStop: function () {
                        return _.cloneDeep(options.routeStop);
                    },
                    isCallOrder: function () {
                        return options.routeStop.hasOwnProperty('callOrderId');
                    },
                    deviationList: function () {
                        return options.deviationList || [];
                    },
                    setToNo: function () {
                        return options.setToNo || false;
                    },
                    setUnitsToUnselected: function () {
                        return options.setUnitsToUnselected || false;
                    },
                    preSelectedCategories: function () {
                        return options.preSelectedCategories || [];
                    }
                }
            });
        }
    }
})();
