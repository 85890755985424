(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('authService', authService);

    authService.$inject = ['$q', '$http', '$httpParamSerializer', '$localForage', 'serviceUrls', 'authServiceUtils', 'cacheServiceUtils', 'appVersion'];

    function authService($q, $http, $httpParamSerializer, $localForage, serviceUrls, authServiceUtils, cacheServiceUtils, appVersion) {
        const authServiceVersion = '1.0.4';
        var authData = {
            customerId: '',
            initialized: false,
            isAuth: false,
            token: '',
            username: '',
            version: authServiceVersion,
            appVersion: appVersion
        };

        var authService = {
            login: login,
            logout: logout,
            getAuthData: getAuthData,
            isValidToken: isValidToken,
            isUpToDate: isUpToDate
        };

        return authService;

        function isUpToDate() {
            return authData.version === authServiceVersion;
        }

        function login(requestUsername, password) {
            const deferred = $q.defer();

            if (!requestUsername || !password) {
                deferred.reject();
                return deferred.promise;
            }

            const lowerCaseUsername = requestUsername.toLowerCase();

            mergeCacheByUsername(requestUsername)
            .then(function(){
                const tokenPromise = getToken(lowerCaseUsername, password);
                return tokenPromise;
            })
            .then(function (result) {
                authData.initialized = true;
                authData.isAuth = true;
                authData.customerId = result.customerId;
                authData.token = result.token;
                authData.username = lowerCaseUsername;
                authData.version = authServiceVersion;

                return $localForage.setItem('authData', authData);
            })
            .then(function () {
                deferred.resolve();
            })
            .catch(function() {
                deferred.reject();
            });

            return deferred.promise;
        }

        /**
         * Transforms and merges all localForage keys that start with a given username into lowercase
         * If there are multiple variants of the username in the cache, chooses requestUsername > lowerCaseUsername > first variant
         * Deletes all other keys associated with the username.
         * @param {string} username - The requested username
         * @returns {Promise<void>} A promise that resolves when the transformation is complete or rejects if an error occurs.
         */
        async function mergeCacheByUsername(username) {
            const lowerCaseUsername = username.toLowerCase();

            return $localForage.keys().then(function (keys) {
                const ownKeys = cacheServiceUtils.filterCacheKeysByUsername(keys, username);

                if (ownKeys.length === 0) {
                    return;
                }

                // e.g., ["JohnDoe", "johndoe", "JOHNDOE"]
                const nameVariants = authServiceUtils.getUsernameVariantsByCacheKey(ownKeys);

                if (nameVariants.length === 0) {
                    return;
                }

                const cachedUsernameToUse = authServiceUtils.getCachedUsernameToUse(nameVariants, username);

                const toDeleteKeys = ownKeys.filter(function (key) {
                    const name = key.split("_")[0];
                    return name !== cachedUsernameToUse;
                });

                const toRenameKeys = ownKeys.filter(function (key) {
                    const name = key.split("_")[0];
                    return name === cachedUsernameToUse;
                });

                const toRenamePromises = toRenameKeys.map(function (key) {
                    const newName = key.replace(cachedUsernameToUse, lowerCaseUsername);
                    return cacheServiceUtils.renameKey(key, newName);
                });

                return Promise.all(toRenamePromises);

                /** @todo delete is postponed */

                // return cacheServiceUtils.deleteMultipleKeys(toDeleteKeys).then(function () {
                //     if (username === lowerCaseUsername) {
                //         return;
                //     }
                // });
            });
        }
        

        function logout() {
            var deferred = $q.defer();

            $http.post(serviceUrls.authorization + '/logout')
                .then(function () {
                    return clearAuthData();
                })
                .then(function () {
                    deferred.resolve();
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function getAuthData() {
            var deferred = $q.defer();

            if (!authData || !authData.initialized) {
                $localForage.getItem('authData').then(function (storedAuthData) {
                    if (storedAuthData) {
                        authData.customerId = storedAuthData.customerId;
                        authData.initialized = true;
                        authData.isAuth = storedAuthData.isAuth;
                        authData.token = storedAuthData.token;
                        authData.username = storedAuthData.username;
                        authData.version = storedAuthData.version;
                        authData.appVersion = storedAuthData.appVersion;
                    }
                    deferred.resolve(authData);
                }, function () {
                    $localForage.removeItem('authData').then(function () {
                        deferred.resolve(authData);
                    });
                });
            } else {
                deferred.resolve(authData);
            }

            return deferred.promise;
        }

        function isValidToken(token) {
            var deferred = $q.defer();

            $http.get(serviceUrls.authorization + '/' + token).then(function (result) {
                deferred.resolve(result.data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function clearAuthData() {
            var deferred = $q.defer();

            $localForage.removeItem('authData').then(function () {
                authData.customerId = '';
                authData.isAuth = false;
                authData.token = '';
                authData.username = '';
                authData.version = '';

                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getToken(username, password) {
            var deferred = $q.defer();

            $http.post(serviceUrls.authorization, {
                username: username,
                password: password
            }).then(function (response) {
                if (response && response.data) {
                    deferred.resolve(response.data);
                } else {
                    deferred.reject();
                }
            }, function() {
                deferred.reject();
            });

            return deferred.promise;
        }
    }
})();
