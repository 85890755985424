(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('routeStopsService', routeStopsService);

    routeStopsService.$inject = ['$q', '$http', '$rootScope', '$log', 'settingsService', 'serviceUrls', 'authService', 'cacheService', 'RouteStop', 'commonUtil', 'updateQueue', 'appVersion', 'orderDataKeys', 'routeStopUtils', 'settingKeys', 'displayProfileType', 'deviationService'];

    function routeStopsService($q, $http, $rootScope, $log, settingsService, serviceUrls, authService, cacheService, RouteStop, commonUtil, updateQueue, appVersion, orderDataKeys, routeStopUtils, settingKeys, displayProfileType, deviationService) {
        var cachePrefix = '_routeStops_',
            getRouteLocks = {},
            getRouteCalls = {},
            getReportedRouteLocks = {},
            getReportedRouteCalls = {},
            reportedRouteCachePrefix = '_reportedRouteStops_',
            routeStopFilterByCachePrefix = '_routeStopFilterBy',
            routeStopsSplitViewStatusCachePrefix = 'routeStops_splitViewStatus',
            routeStopSortByCachePrefix = '_routeStopSortBy',
            hubConnection;

        const displayProfileTypeEnum = displayProfileType;

        var routeStopFilterType, routeStopsSplitViewStatus, routeStopSortType;

        var routeStopsService = {
            deleteLocalRoute: deleteLocalRoute,
            deleteRouteRequest: deleteRouteRequest,
            getContainerDetails: getContainerDetails,
            getFilterBy: getFilterBy,
            getCachedFilterBy: getCachedFilterBy,
            getLocalRouteStop: getLocalRouteStop,
            getOrderDataByKey: getOrderDataByKey,
            getReportedRoute: getReportedRoute,
            getRoute: getRoute,
            getRouteLinesInRadius: getRouteLinesInRadius,
            getSplitViewStatus: getSplitViewStatus,
            getSortBy: getSortBy,
            getTransformedOrderData: getTransformedOrderData,
            hasDeviationPicturesByUnit: hasDeviationPicturesByUnit,
            queueRouteDelete: queueRouteDelete,
            saveFilterBy: saveFilterBy,
            saveLocalRouteStop: saveLocalRouteStop,
            saveReportedRouteStops: saveReportedRouteStops,
            saveSplitViewStatus: saveSplitViewStatus,
            saveSortBy: saveSortBy,
            updateRouteStop: updateRouteStop,
            updateRouteStopRequest: updateRouteStopRequest,
            bulkUpdateRouteStopRequests: bulkUpdateRouteStopRequests,
            addRouteStopToLocalRoute: addRouteStopToLocalRoute,
            updateRouteStopCoords: updateRouteStopCoords,
            updateUnitCoordinates: updateUnitCoordinates
        };

        initService();

        return routeStopsService;

        function initService() {
            getCachedFilterBy().then(fType => {
                routeStopFilterType = fType;
            });

            cacheService.get(routeStopsSplitViewStatusCachePrefix).then(function (splitViewStatus) {
                routeStopsSplitViewStatus = splitViewStatus || 'horizontal';
            });

            cacheService.get(routeStopSortByCachePrefix).then(function (sortType) {
                routeStopSortType = sortType || 'distance';
            });
        }

        function getCachedFilterBy() {
            var deferred = $q.defer();
            cacheService.get(routeStopFilterByCachePrefix).then(function (filterType) {
                deferred.resolve(filterType || 'description');
            });

            return deferred.promise;
        }

        function updateRouteStopCoords(orderId, routeStop) {
            var deferred = $q.defer();

            $http.put('api/routeStops/adjustposition/' + routeStop.routeLineId, { longitude: routeStop.longitude, latitude: routeStop.latitude }).then(function (response) {
                if (response && response.data) {
                    return updateRouteStop(orderId, routeStop);
                } else {
                    return $q.reject();
                }
            }).then(function () {
                deferred.resolve();
            }).catch(function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function deleteLocalRoute(orderId, guardFunction) {
            var deferred = $q.defer();

            var cacheKey = cachePrefix + orderId;

            var isDeleted = false;
            cacheService.get(cacheKey)
                .then(function (route) {
                    if (guardFunction(route)) {
                        isDeleted = true;
                        return cacheService.remove(cacheKey);
                    }
                })
                .then(function () {
                    deferred.resolve(isDeleted);
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function deleteRouteRequest(orderId) {
            return deleteRoute(orderId);
        }

        function getContainerDetails(orderId, containerId) {
            var deferred = $q.defer();

            $http.get('api/orders/' + orderId + '/containerDetails/' + containerId).then(function (response) {
                if (response && response.data) {
                    deferred.resolve(response.data);
                } else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;

        }

        function getFilterBy() {
            return routeStopFilterType;
        }

        function addRouteStopToLocalRoute(routeStop, orderId) {
            
            var cacheKey = cachePrefix + orderId;
            var deferred = $q.defer();


            cacheService.addToIn(cacheKey, routeStop, true, 'routeStops').then(function() {
                deferred.resolve();
            },
                function(err) {
                    deferred.reject();
                });
            return deferred.promise;
        }

        function getLocalRouteStop(orderId, routeStopId) {
            var deferred = $q.defer();

            var cacheKey = cachePrefix + orderId;

            cacheService.get(cacheKey)
                .then(function (route) {
                    var routeStop = route ? _.find(route.routeStops, { "routeLineId": routeStopId }) : null;
                    if (!routeStop) {
                        routeStop =  _.find(route.routeStops, { "callOrderId": routeStopId });
                    }
                    deferred.resolve(routeStop);
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function getReportedRoute(orderId, refreshCache) {
            var deferred = $q.defer();
            var key = orderId;

            if (!getReportedRouteLocks[key]) {
                getReportedRouteLocks[key] = true;

                var reportedRoute;
                var cacheKey = reportedRouteCachePrefix + orderId;

                var request = refreshCache === true
                    ? $http.get('api/orders/' + orderId + '/reportedroute')
                    : Promise.resolve();

                request
                    .then(function (response) {
                        if (response && response.data) {
                            reportedRoute = response.data;
                            return saveReportedRouteStops(orderId, reportedRoute);
                        }
                    })
                    .catch(function () {
                        // ignore
                    })
                    .then(function () {
                        return reportedRoute || cacheService.get(cacheKey);
                    })
                    .then(function (reportedRoute) {
                        if (reportedRoute) {
                            deferred.resolve(reportedRoute);
                        } else {
                            deferred.reject();
                        }
                    })
                    .catch(function () {
                        deferred.reject();
                    })
                    .finally(function () {
                        getReportedRouteLocks[key] = false;
                        var getReportedRouteCall = getReportedRouteCalls[key] ? getReportedRouteCalls[key].pop() : null;

                        if (getReportedRouteCall) {
                            getReportedRoute.apply(null, getReportedRouteCall.args).then(function (result) {
                                getReportedRouteCall.deferred.resolve(result);
                            }, function () {
                                getReportedRouteCall.deferred.reject();
                            });
                        }

                        deferred.resolve(reportedRoute);
                    });
            } else {
                getReportedRouteCalls[key] = getReportedRouteCalls[key] || [];
                getReportedRouteCalls[key].unshift({ args: arguments, deferred: deferred });
            }

            return deferred.promise;
        }

        function getRoute(orderId, position) {
            var deferred = $q.defer();
            var key = orderId;

            if (!getRouteLocks[key]) {
                getRouteLocks[key] = true;
                var prefix = cachePrefix + orderId;
                var route;

                cacheService.has(prefix)
                    .then(function (exists) {
                        if (exists) {
                            return cacheService.get(prefix);
                        } else {
                            return getServerRoute(orderId, position);
                        }
                    })
                    .then(function (route) {
                        return transformRoute(_.cloneDeep(route));
                    })
                    .then(function(route) {
                        deferred.resolve(route);
                    })
                    .catch(function () {
                        deferred.reject();
                    })
                    .finally(function () {
                        getRouteLocks[key] = false;
                        var getRouteCall = getRouteCalls[key] ? getRouteCalls[key].pop() : null;

                        if (getRouteCall) {
                            getRoute.apply(null, getRouteCall.args).then(function (result) {
                                getRouteCall.deferred.resolve(result);
                            }, function () {
                                getRouteCall.deferred.reject();
                            });
                        }

                        deferred.resolve(route);
                    });
            } else {
                getRouteCalls[key] = getRouteCalls[key] || [];
                getRouteCalls[key].unshift({ args: arguments, deferred: deferred });
            }

            return deferred.promise;
        }

        function getRouteLinesInRadius(radius, maxHits, lat, lng) {
            var deferred = $q.defer();

            var proximityDto = {
                radius: radius,
                maxHits: maxHits,
                latitude: lat,
                longitude: lng
            };

            var qStr = '?'+$.param(proximityDto);
            $http.get(serviceUrls.orders + '/routelinesinradius/' + qStr).then(function (response) {
                if (response && response.data) {
                    deferred.resolve(response.data);
                } else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getSplitViewStatus() {
            return routeStopsSplitViewStatus;
        }

        function getSortBy() {
            return routeStopSortType;
        }

        function saveFilterBy(filterBy) {
            routeStopFilterType = filterBy;

            cacheService.set(routeStopFilterByCachePrefix, filterBy);
        }

        function saveLocalRouteStop(orderId, routeStop) {
            var deferred = $q.defer();

            var cacheKey = cachePrefix + orderId;

            const routeLineId = routeStop.routeLineId ? routeStop.routeLineId : routeStop.callOrderId;
            cacheService.replaceIn(cacheKey, 'routeStops', routeStop, 'routeLineId', routeLineId).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function saveReportedRouteStops(orderId, reportedRouteStops) {
            var deferred = $q.defer();

            console.log(reportedRouteStops)
            reportedRouteStops = _.uniqBy(reportedRouteStops, 'routeLineId');

            cacheService.set(reportedRouteCachePrefix + orderId, reportedRouteStops).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function saveSplitViewStatus(splitViewStatus) {
            routeStopsSplitViewStatus = splitViewStatus;

            cacheService.set(routeStopsSplitViewStatusCachePrefix, splitViewStatus);
        }

        function saveSortBy(sortBy) {
            routeStopSortType = sortBy;

            cacheService.set(routeStopSortByCachePrefix, sortBy);
        }

        function queueRouteDelete(orderId) {
            var deferred = $q.defer();

            var uploadAction = {
                id: commonUtil.generateGuid(),
                parameters: [orderId],
                type: 'deleteRoute'
            };

            updateQueue.addUpdateAction(uploadAction)
                .then(function () {
                    deferred.resolve();
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function updateRouteStop(orderId, routeStop) {
            var deferred = $q.defer();

            saveLocalRouteStop(orderId, routeStop).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getOrderDataByKey(routeStop, key) {
            if (!routeStop || !key || !routeStop.orderData) return null;
            const foundValue = routeStop.orderData.find(orderData => orderData.key === key)?.value;
            return foundValue ?? null;
        }

        function getTransformedOrderData(routeStop, key) {
            const value = getOrderDataByKey(routeStop, key);
            if (!value) return null;
            switch (key) {
                //Array<string>
                case orderDataKeys.latestDeviations:
                    const parsed = JSON.parse(value);
                    const sortedByCreatedAt = parsed.sort((a, b) => new Date(b.CreatedAt) - new Date(a.CreatedAt));
                    const duplicatesRemoved = sortedByCreatedAt.reduce((acc, current) => {
                        const x = acc.find(agreement => agreement.Deviations === current.Deviations)
                        if (!x) {
                            return acc.concat([current])
                        } else {
                            return acc
                        }
                    }, []);
                    const mapped = duplicatesRemoved.map(agreement => {
                        const date = moment(agreement?.CreatedAt).format('DD.MM');
                        const string = `${agreement?.Deviations} (${date})`;
                        return string || '';
                    });
                    const filtered = mapped.filter(deviation => deviation);
                    return filtered;
                //Array<string>
                case orderDataKeys.infoOnKeys:
                    const infoOnKeys = commonUtil.arrayFromString(value, ';', true);
                    return infoOnKeys;
                default:
                    return value;
            }
        }

        function hasDeviationPicturesByUnit(routeLineId, orderId, agreementLineId){
            const deferred = $q.defer();

            cacheService.get('_deviation_' + orderId)
            .then(function(orderDeviationPictures){
                if (!orderDeviationPictures) return deferred.resolve(false);
                const routeStopDeviation = orderDeviationPictures.find(deviation => deviation.routeStopId === routeLineId && deviation.agreementLineIds.includes(agreementLineId));

                deferred.resolve(!!routeStopDeviation?.imageHash || false);
            })
            .catch(function(){
                deferred.resolve(false);
            });

            return deferred.promise;
        }

        function updateRouteStopRequest(orderId, routeStopId) {
            var deferred = $q.defer();
            console.log('updateRouteStopRequest', routeStopId)
            getLocalRouteStop(orderId, routeStopId)
                .then(function (routeStop) {
                    if (routeStop) {
                        $http.put(serviceUrls.orders + '/' + orderId + '/routeStops', [routeStop])
                            .then(function () {
                                routeStop.status = 'uploaded';
                                return saveLocalRouteStop(orderId, routeStop);
                            })
                            .then(function () {
                                $rootScope.$broadcast('routeStopUploaded', routeStop);
                                deleteLocalRoute(orderId,
                                    function (route) {
                                        return _.every(route.routeStops, { status: 'uploaded' });
                                    });
                                deferred.resolve();
                            })
                            .catch(function () {
                                deferred.reject();
                            });
                    } else {
                        $log.error('Cannot upload route stop (id: ' + routeStopId + ') because there is no local route (order: ' + orderId + ')');
                        deferred.resolve();
                    }
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        async function bulkUpdateRouteStopRequests(list = []) {
            const filteredList = list.filter((i) => i.orderId && i.routeStopId);
            const sameOrderIdElements = filteredList.reduce((acc, current) => {
                const x = acc[`${current.orderId}`];
                if (!x) {
                    return { ...acc, [`${current.orderId}`]: [current] };
                } else {
                    return { ...acc, [`${current.orderId}`]: [...x, current] };
                }
            }, {});
        
            const promises = Object.keys(sameOrderIdElements).map(async (orderId) => {
                const routeStops = sameOrderIdElements[orderId];
                const routeStopIds = routeStops.map((i) => i.routeStopId);
                const localRouteStopsRequests = await Promise.all(
                    routeStopIds.map(async (routeStopId) => await getLocalRouteStop(orderId, routeStopId))
                );
                const localRouteStops = localRouteStopsRequests.filter((i) => i);
                if (!localRouteStops.length)
                    throw new Error(
                        "Cannot upload route stop because there is no local route (order: " +
                            orderId +
                            ")"
                    );
                await $http.put(
                    serviceUrls.orders + "/" + orderId + "/routeStops",
                    localRouteStops
                );
                const toSaveRouteStops = localRouteStops.map(async (routeStop) => {
                    $rootScope.$broadcast("routeStopUploaded", routeStop);
                    const obj = { ...routeStop, status: "uploaded" };
                    return await saveLocalRouteStop(orderId, obj);
                });
                await Promise.all(toSaveRouteStops);
                deleteLocalRoute(orderId, function (route) {
                    return _.every(route.routeStops, { status: "uploaded" });
                });
            });
        
            return Promise.all(promises);
        }        

        // private functions
        function deleteRoute(orderId) {
            var deferred = $q.defer();

            var cacheKey = cachePrefix + orderId;

            cacheService.remove(cacheKey).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getServerRoute(orderId, position) {
            var deferred = $q.defer();

            var route;
            authService.getAuthData('authData').then(function (authData) {
                if (authData) {
                    var options = {
                        logging: signalR.LogLevel.Information,
                        accessTokenFactory: function () {
                            return authData.token;
                        }
                    };

                    hubConnection = new signalR.HubConnectionBuilder()
                        .withUrl('routehub?customerid=' + authData.customerId, options)
                        .build();

                    hubConnection.on("retryTriggered", function () {
                        $rootScope.$broadcast('getRouteRetryTriggered');
                    });

                    hubConnection.start()
                        .then(function () {
                            return hubConnection.invoke("GetRouteWithDynamicOrderData", orderId);
                        })
                        .then(function (response) {
                            if (response) {
                                route = {
                                    name: response.name,
                                    routeStops: _.map(response.routelines,
                                        function (routeline) {
                                            var routeStop = new RouteStop(routeline,
                                                position,
                                                $rootScope.userSettings.dataButtons
                                            );
                                            routeStop.customerId = authData.customerId;
                                            routeStop.appVersion = appVersion;
                                            return routeStop;
                                        })
                                };
                                return cacheService.set(cachePrefix + orderId, route);
                            }
                        })
                        .then(function () {
                            hubConnection.stop();
                            if (route) {
                                deferred.resolve(route);
                            } else {
                                deferred.reject();
                            }
                        })
                        .catch(function () {
                            deferred.reject();
                        });
                } else {
                    deferred.reject();
                }
            });

            return deferred.promise;
        }

        function transformRoute(route) {
            const deferred = $q.defer();

            const promiseArr = [
                settingsService.getOne(settingKeys.DisplayProfileType),
                settingsService.getListOf([settingKeys.DataButton1ProfileTypes, settingKeys.DataButton2ProfileTypes, settingKeys.DataButton3ProfileTypes, settingKeys.DataButton4ProfileTypes, settingKeys.DataButton5ProfileTypes]),
                settingsService.getCategories(),
                settingsService.getDeviations(),
                settingsService.getOne(settingKeys.FavouriteDeviationIds),
                settingsService.getOne(settingKeys.WalkingDistanceUnitIds),
            ];

            $q.all(promiseArr)
            .then(function (results) {
                const [
                    displayProfileType,
                    dataButtonProfileTypes,
                    deviationCategories,
                    deviations,
                    favouriteDeviationIdsString,
                    walkingDistanceUnitIdsString,
                ] = results;

                const dataButtonProfileTypeArray = dataButtonProfileTypes?.map((dataButtonProfileType) => {
                    if(!dataButtonProfileType) return [];
                    return dataButtonProfileType.split(',').map( d=> routeStopUtils.getWasteClass(d.trim()));
                }) || [];

                const favouriteDeviationIdsArray = commonUtil.arrayFromString(favouriteDeviationIdsString, ',', true) || [];
                const walkingDistanceUnitIdsArray = commonUtil.arrayFromString(walkingDistanceUnitIdsString, ',', true) || [];

                const deviationList = deviationService.sortDeviations(deviations);

                const routeStopProps = {
                    displayProfileType: displayProfileType,
                    dataButtonProfileTypeArray: dataButtonProfileTypeArray,
                    deviationCategories: deviationCategories,
                    deviationList: deviationList,
                    favouriteDeviationIdsArray: favouriteDeviationIdsArray,
                    walkingDistanceUnitIdsArray: walkingDistanceUnitIdsArray
                }
                      
                route.routeStops = _.map(route.routeStops, function (routeStop) {
                    const transformedRouteStop = routeStop.callOrderId ? routeStop : transformRouteStop(routeStop, routeStopProps);
                    const routeStopInstance = new RouteStop();
                    const merged = _.merge(routeStopInstance, transformedRouteStop);
                    return merged;
                });
    
                deferred.resolve(route);
            })
            .catch(function () {
                deferred.resolve(route);
            });

            return deferred.promise;
        }

        function transformRouteStop(routeStop, routeStopProps) {
            if (!routeStop) return routeStop;

            const {
                displayProfileType,
                dataButtonProfileTypeArray,
                deviationCategories,
                deviationList,
                favouriteDeviationIdsArray,
                walkingDistanceUnitIdsArray
            } = routeStopProps;
            const favoriteDeviations = deviationService.getFavoriteDeviations(deviationList, favouriteDeviationIdsArray);
            
            const infoCarousel = {
                [orderDataKeys.infoOnKeys]: routeStopsService.getTransformedOrderData(routeStop, orderDataKeys.infoOnKeys) || [],
                [orderDataKeys.latestDeviations]: routeStopsService.getTransformedOrderData(routeStop, orderDataKeys.latestDeviations) || [],
            }

            routeStop.displayProfileType = routeStop.displayProfileType ? routeStop.displayProfileType : displayProfileType;
            routeStop.deviationCategories = deviationCategories;
            routeStop.favoriteDeviations = favoriteDeviations;
            routeStop.infoCarousel = infoCarousel;

            if (routeStop.displayProfileType === displayProfileTypeEnum.profile01) {
                const dataButtons = getDataButtonsByProfileType(routeStop.dataButtons, routeStop.wasteType, dataButtonProfileTypeArray);
                const hasMessagePurposeUnit = routeStopHasMessagePurposeUnit(routeStop, walkingDistanceUnitIdsArray);

                routeStop.dataButtons = dataButtons;
                routeStop.hasMessagePurposeUnit = hasMessagePurposeUnit;
                if (routeStop.units?.length)
                {
                    const modifiedUnits = routeStop.units.map((unit) => {
                        return { ...unit, isMessagePurpose: unitIsMessagePurpose(unit, walkingDistanceUnitIdsArray)}
                    }).sort((a, b) => b.isMessagePurpose - a.isMessagePurpose);
                    routeStop.units = modifiedUnits;
                }
            }
            
            return routeStop;
        }

        function unitIsMessagePurpose(unit, walkingDistanceUnitIdsArray) {
            if (!unit || !walkingDistanceUnitIdsArray?.length) return false;
            const unitIdString = unit.unitId ? `${unit.unitId}` : '';
            return walkingDistanceUnitIdsArray.includes(unitIdString);
        }

        function routeStopHasMessagePurposeUnit(routeStop, walkingDistanceUnitIdsArray) {
            if (!routeStop.units?.length || !walkingDistanceUnitIdsArray?.length) return false;
            const hasMessagePurposeUnit = routeStop.units.some((unit) => {
                return unitIsMessagePurpose(unit, walkingDistanceUnitIdsArray);
            });
            return hasMessagePurposeUnit;
        }

        function getDataButtonsByProfileType(dataButtons, wasteType, dataButtonProfileTypes) {
            const actualWasteType = routeStopUtils.getWasteClass(wasteType);
            const everyProfileTypeIsEmpty = dataButtonProfileTypes.every((dataButtonProfileType) => !dataButtonProfileType.length);
            if (!dataButtonProfileTypes.length || everyProfileTypeIsEmpty) return dataButtons;
            const modifiedDataButtonList = dataButtons?.filter((dataButton) => {
                if (!dataButton.parameter || dataButton.parameter.length < 11) return false;
                else {
                    const buttonNumber = Number(dataButton.parameter.substring(10));
                    const dataButtonProfileTypeArray = dataButtonProfileTypes[buttonNumber-1];
                    if (!dataButtonProfileTypeArray.length) return false;
                    return dataButtonProfileTypeArray.includes(actualWasteType);
                }
            }) || [];
            return modifiedDataButtonList;
        }

        function updateUnitCoordinates(agreementLineId, paSystem, x, y) {
            var deferred = $q.defer();

            $http.put('api/units/adjustposition/' + agreementLineId, { longitude: x, latitude: y, paSystem: paSystem })
            .then(function (response) {
                if (response && response.status === 200) {
                    deferred.resolve();
                } else {
                    deferred.reject();
                }
            })
            .catch(function () {
                deferred.reject();
            });

            return deferred.promise;
        }
    }
})();
