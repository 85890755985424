(function () {
    'use strict';
    angular.module('PWAPoCApp').controller('SetDriverModalController', setDriverModalController);

    setDriverModalController.$inject = ['$q', '$scope', '$kWindow', '$windowInstance', 'driverList', 'settingsService', 'appSettings', 'translateService'];

    function setDriverModalController($q, $scope, $kWindow, $windowInstance, driverList, settingsService, appSettings, translateService) {

        $scope.translate = translateService.translate;
        $scope.selectedDriver = null;
        $scope.driverList = driverList;
        $scope.mode = 'selectDriver';
        $scope.setDriver = function (driver, evt) {
            preventPropagation(evt);
            $scope.selectedDriver = driver;
        }

        $scope.isSelected = function(driver){
            return driver === $scope.selectedDriver;
        }

        $scope.set = function () {
            if (!appSettings.setDriverPasswordCheckRequired) {
                setSelectedDriver();
            }else {
                clearPasswords();
                if ($scope.selectedDriver) {
                    $scope.mode = 'enterPassword';
                }
                setTimeout(() =>document.getElementById("password-field").focus(), 100);
            }
        }

        $scope.cancel = function (evt) {
            preventPropagation(evt);
            clearPasswords();
            if ($scope.selectedDriver) {
                $scope.selectedDriver = null;
                $scope.mode = 'selectDriver';
            }
        }

        $scope.submitPassword = function (evt) {
            preventPropagation(evt);
        }

        function setSelectedDriver() {
            if ($scope.selectedDriver) {
                settingsService.setDriver($scope.selectedDriver.driverId).then(() => $windowInstance.close());
            }
        }

        function clearPasswords() {
            $scope.realPassword = "";
            $scope.fakePassword = "";
        }
        function preventPropagation(evt) {
            evt.stopPropagation();
            evt.preventDefault();
        }
    }
})();
