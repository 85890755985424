(function () {
    'use strict';

    angular.module('PWAPoCApp').controller('MessagesController', messagesController);

    messagesController.$inject = [
        '$rootScope',
        '$scope',
        'messagesService',
        'messageState',
        '$windowInstance',
        'smsReceiptService',
        'routeStop',
        'settingsService',
        'translateService'
    ];

    function messagesController(
        $rootScope,
        $scope,
        messagesService,
        messageState,
        $windowInstance,
        smsReceiptService,
        routeStop,
        settingsService,
        translateService
    ) {
        $scope.isNew = isNew;
        $scope.messageClick = messageClick;
        $scope.close = close;
        $scope.insertTemplate = insertTemplate;
        $scope.postMessage = postMessage;
        $scope.canSendMessage = true;
        $scope.translate = translateService.translate;

        $scope.phoneNumber = '';
        $scope.messageText = '';

        initController();

        function initController() {
            if (routeStop) {
                $scope.phoneNumber = getRouteStopPhoneNumber(routeStop);

                smsReceiptService.createSmsReceipt(routeStop).then(receipt => {
                    $scope.messageText = receipt;
                });
            }
        }

        function isNew(message) {
            return message.messageState === messageState.new;
        }

        function messageClick(message) {
            //unselect all others
            _.forEach($rootScope.directMessages, function (m) {
                if (m.id !== message.id) {
                    m.isSelected = false;
                }
            });

            message.isSelected = !message.isSelected;

            if (message.messageState === messageState.new) {
                messagesService.updateMessageStatus(message.id).then(function () {
                    message.messageState = messageState.readProfile;
                    $rootScope.messageBadgeCount--;

                    if ($rootScope.messageBadgeCount === 0) {
                        $rootScope.showMessageBadge = false;
                    }
                });
            }
        }

        function close() {
            $windowInstance.close(false);
        }

        function insertTemplate(template) {
            $scope.messageText = template;
        }

        function postMessage(event) {
            event.preventDefault();

            $scope.canSendMessage = false;

            $rootScope.$broadcast('showBusyIndicator');

            settingsService.getSmsSenderInfo().then(smsSenderInfo => {
                var message = {
                    phoneNumber: $scope.phoneNumber,
                    text: $scope.messageText,
                    smsSenderId: smsSenderInfo[0],
                    smsSenderName: smsSenderInfo[1]
                };

                return message;
            }).then(message => {
                messagesService.sendMessage(message).then(() => {
                    $scope.canSendMessage = true;

                    $rootScope.$broadcast('hideBusyIndicator');

                    $windowInstance.close($scope.phoneNumber);
                }, () => {
                    $scope.canSendMessage = true;

                    $rootScope.$broadcast('hideBusyIndicator');

                    $windowInstance.close(false);
                });
            });
        }

        /**
         * Extracts the phone number from the "agreement_Other" field of the routeStop.
         * This field contains a maximum of 2 phone numbers separated with a space.
         * The last phone number in the field gets prioritized. 
         * 
         * Example: +47 98765432 98765431
         * @param {} routeStop 
         */
        function getRouteStopPhoneNumber(routeStop) {
            if (routeStop && routeStop.orderData) {
                var agreementOtherObject = _.find(routeStop.orderData,
                    od => {
                        return od.key === '[agreement_Other]';
                    });

                if (agreementOtherObject && agreementOtherObject.value) {
                    var matches = agreementOtherObject.value.match(/((\+?47 ?)?\d{8})/gim);

                    if (matches) {
                        return matches[1] || matches[0] || '';
                    }
                }
            }

            return '';
        }
    }
})();
