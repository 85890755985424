(function () {
    'use strict';

    angular.module('PWAPoCApp').controller('SendReportController', sendReportController);

    sendReportController.$inject = ['$rootScope', '$scope', '$windowInstance', 'errorReportService', 'translateService'];

    function sendReportController($rootScope, $scope, $windowInstance, errorReportService, translateService) {
        $scope.send = send;
        $scope.translate = translateService.translate;
        $scope.message = '';

        function send() {
            if ($scope.message.length > 0) {
                $rootScope.$broadcast('showBusyIndicator');
                errorReportService.sendErrorReport($scope.message)
                    .then(function () {
                        alert(translateService.translate('support.reportSent'));
                        $windowInstance.close();
                    })
                    .catch(function () {
                        alert(translateService.translate('support.reportError'));
                    })
                    .finally(function () {
                        $rootScope.$broadcast('hideBusyIndicator');
                    });
            } else {
                alert(translateService.translate('support.messageRequiredError'));
            }
        }
    }
})();
