(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('translateService', translateService);

    translateService.$inject = [
        '$rootScope',
        'norwegianTranslations',
        'englishTranslations'
    ];

    function translateService(
        $rootScope,
        norwegianTranslations,
        englishTranslations
    ) {

        const service = {
            translate,
        };

        return service;

        function translate(stringToTranslate, fallbackValue = '', variables = {}) {
            if (!stringToTranslate) {
                return fallbackValue || '';
            }

            const translations = {
                'no': norwegianTranslations,
                'en': englishTranslations,
            };

            const language = $rootScope.selectedLanguage || 'no';

            const translation = stringToTranslate.split('.').reduce((obj, i) => obj[i], translations[language]);

            if (!translation) {
                return fallbackValue || '';
            }

            return replaceVariables(translation, variables);
        }

        function replaceVariables(string, variables) {
            if (_.isEmpty(variables)) return string;

            for (let key in variables) {
                if (variables[key]) {
                    string = string.replace(`{${key}}`, variables[key]);
                }
            }

            return string;
        }
    }
})();
