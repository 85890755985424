(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('routeStopsMessageService', routeStopsMessageService);

    routeStopsMessageService.$inject = ['messagesService', '$kWindow', 'translateService'];

    function routeStopsMessageService(messagesService, $kWindow, translateService) {

        var routeStopsMessageService = {
            showRouteStopMessage: showRouteStopMessage
        };

        function showRouteStopMessage(routeStop) {
            messagesService.getAllRouteStopMessages(routeStop.agreementLines[0].agreementId)
            .then(function (messages) {
                if (!messages?.length) return;
                messages.forEach((message) => {
                    $kWindow.open({
                        options: {
                            modal: true,
                            title: translateService.translate('directMessage.messages'),
                            draggable: false,
                            movable: false,
                            resizable: false,
                            visible: false,
                            height: 150,
                            width: 350
                        },
                        templateUrl: 'app/routeStops/modals/route-stop-message-view.html',
                        windowTemplateUrl: 'app/shared/modal-base.html',
                        controller: 'RouteStopMessageController',
                        resolve: {
                            message: function () {
                                return message.text;
                            }
                        }
                    });
                });
            });
        }

        return routeStopsMessageService;

    }
})();
