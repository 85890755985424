(function () {
    'use strict';

    angular.module('PWAPoCApp').controller('callOrdersController', callOrdersController);

    callOrdersController.$inject = ['$q', '$rootScope', '$scope', '$state', '$windowInstance', 'callOrdersService', 'commonUtil', 'orderStatusTransitions', 'translateService'];

    function callOrdersController($q, $rootScope, $scope, $state, $windowInstance, callOrdersService, commonUtil, orderStatusTransitions, translateService) {
        $scope.activeCallOrder = null;
        $scope.callOrders = [];

        $scope.addSelected = addSelected;
        $scope.canAddToRouteStopsList = canAddToRouteStopsList;
        $scope.close = close;
        $scope.hasSelectedCallOrder = hasSelectedCallOrder;
        $scope.getDisplayName = getDisplayName;
        $scope.map = map;
        $scope.refreshCallOrders = refreshCallOrders;
        $scope.removeSelected = removeSelected;
        $scope.toggleCallOrder = toggleCallOrder;
        $scope.translate = translateService.translate;

        initController();

        function initController() {
            getCallOrders().then(function() {
                InitializeModalView();
                _.each($scope.callOrders, function (callOrder, index) {
                    selectCallOrder(callOrder, index === 0);
                });
            });
        }

        function addSelected() {
            if (canAddToRouteStopsList()) {
                var callOrdersToAdd = _.remove($scope.callOrders, { selected: true });
                var routeStops = _.flatten(_.map(callOrdersToAdd, 'routeStops'));

                _.each(callOrdersToAdd, function(callOrderToAdd) {
                    callOrderToAdd.relatedOrderId = $state.params.orderId;
                    callOrdersService.saveCallOrder(callOrderToAdd);
                });

                if ($scope.callOrders.length === 0) {
                    close();
                }

                $scope.activeCallOrder = null;
                $rootScope.$broadcast('routeStopAddRequest', routeStops);
            }
        }

        function canAddToRouteStopsList() {
            return $state.current.name === 'main.routeStops' && hasSelectedCallOrder();
        }

        function close() {
            $windowInstance.close();
        }

        function hasSelectedCallOrder() {
            return _.some($scope.callOrders, { selected: true });
        }

        function getDisplayName(callOrder) {
            var detailDataValue = _.find(callOrder.agreementData, { key: "DetailData_Name" }).value ||
                _.find(callOrder.agreementData, { key: "DetailData_Address1" }).value ||
                _.find(callOrder.agreementData, { key: "DetailData_Address2" }).value;

            return detailDataValue || callOrder.order.orderId;
        }

        function map(value) {
            var result = commonUtil.mapDetailDataKey(value);
            return result;
        }

        function refreshCallOrders() {
            getCallOrders(true);
        }

        function InitializeModalView(){
            $rootScope.overlayDisabled = true;
            $('.k-widget.k-window .k-window-titlebar .k-window-actions').hide();
        }

        function removeSelected() {
            if (hasSelectedCallOrder()) {
                var callOrdersToRemove = _.remove($scope.callOrders, { selected: true });

                _.each(callOrdersToRemove, function (callOrderToRemove) {
                    callOrderToRemove.declined = true;
                    callOrdersService.saveCallOrder(callOrderToRemove);
                });

                $scope.activeCallOrder = null;
            }
        }

        function toggleCallOrder(index) {
            var callOrder = $scope.callOrders.length ? $scope.callOrders[index] : null;
            selectCallOrder(callOrder, !callOrder.selected);
        }

        //private functions
        function getCallOrders(refresh) {
            var deferred = $q.defer();

            $rootScope.$broadcast('showBusyIndicator');

            callOrdersService.getCallOrders(refresh)
                .then(function (callOrders) {
                    $scope.callOrders = _.filter(callOrders, function (callOrder) {
                        return !callOrder.declined && !callOrder.relatedOrderId && callOrder.transitionId !== orderStatusTransitions.complete;
                    });
                    deferred.resolve();
                })
                .catch(function () {
                    deferred.reject();
                })
                .finally(function () {
                    $rootScope.$broadcast('hideBusyIndicator');
                });

            return deferred.promise;
        }

        function selectCallOrder(callOrder, selectedValue) {
            if (callOrder) {
                callOrder.selected = selectedValue;
                $scope.activeCallOrder = callOrder;
            }

            if (callOrder && !callOrder.seen) {
                callOrder.seen = true;
                callOrdersService.saveCallOrder(callOrder);
            }
        }

    }
})();
