(function () {
    'use strict';

    let pwaPoCApp = angular.module('PWAPoCApp');

    pwaPoCApp.constant('formTypes', {
        add: 'add',
        edit: 'edit'
    });

    pwaPoCApp.constant('orderStatusTransitions', {
        start: 0,
        cancel: 1,
        complete: 2,
        delete: 3
    });

    pwaPoCApp.constant('messageState', {
        'new': 0,
        'read': 1,
        'readProfile': 2
    });
    pwaPoCApp.constant('displayProfileType', {
        'default': 0,
        'profile01': 1,
    });

    pwaPoCApp.constant('orderDataKeys', {
        infoOnKeys: 'infoOnKeys',
        latestDeviations: 'latestDeviations'
        /** @todo would require others as well, when being implemented*/
    });

    pwaPoCApp.constant('routeStopConstants', {
        wasteType: {
            types: {
                household: 'pr',
                commercial: 'næ',
                sludge: 'sl',
                cabin: 'hy'
            },
            classes: {
                household: 'household-waste',
                commercial: 'commercial-waste',
                altCommercial: 'alt-commercial-waste',
                sludge: 'sludge-waste',
                cabin: 'cabin'
            },
        },
        system: {
            H: 'H',
            N: 'N',
            PA: 'PA',
        },
    });


    pwaPoCApp.constant('orderDataDescriptions', {
        'DetailData_Name': 'Navn',
        'DetailData_Address1': 'Adresse 1',
        'DetailData_Address2': 'Adresse 2',
        'DetailData_PostalCode': 'Postnummer',
        'DetailData_Other': 'Annet',
        'DetailData_Message': 'Melding',
        'DetailData_OrderId': 'Ordre id',
        'DetailData_Expires': 'Utgår',
        'DetailData_LastDate': 'Siste dato',
        'DetailData_LastVolume': 'Siste volum',
        'DetailData_MessageSeptic': 'Merknad',
        'DetailData_Material': 'Materialtype',
        'DetailData_NumberOfChambers': 'Ant.kammer',
        'DetailData_TubeLength': 'Slangemeter',
        'DetailData_BackingRequired': 'Rygging',
        'DetailData_LengthFromRoad': 'Avstand vei',
        'DetailData_SmallestRoadWidth': 'Smaleste vei',
        'DetailData_Status': 'Status',
        'DetailData_Year': 'År'
    });
})();
