(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('healthInfoService', healthInfoService);

    healthInfoService.$inject = ['$q', '$http', '$rootScope', 'cacheService', 'locationService', 'appVersion', 'serviceUrls', 'appSettings', 'pollingService'];

    function healthInfoService($q, $http, $rootScope, cacheService, locationService, appVersion, serviceUrls, appSettings, pollingService) {
        const cacheKey = "wrongRouteStopUploadRequest";
        let polling = null;
        const service = {
            update: update,
            handleAddNewWrongRouteStopUploadRequest: handleAddNewWrongRouteStopUploadRequest,
            startExpiredWrongRouteStopDeletionPolling: startExpiredWrongRouteStopDeletionPolling,
            stopExpiredWrongRouteStopDeletionPolling: stopExpiredWrongRouteStopDeletionPolling,
        };

        return service;

        function update() {
            const deferred = $q.defer();
            const requests = [getLocalStorageSizes(), locationService.getCurrentPosition(), getWrongRouteStops()];

            $q.all(requests)
                .then(function (data) {
                    const localStorageSizes = data[0],
                        position = data[1],
                        wrongRouteStopList = data[2];

                    if (position && position.coords && position.coords.latitude) {

                        const healthInfo = {
                            accuracy: position.coords.accuracy,
                            latitude: position.coords.latitude,
                            localStorageSizeMB: localStorageSizes.total,
                            localStorageDetailed: localStorageSizes.detailed,
                            longitude: position.coords.longitude,
                            orderId: $rootScope.activeOrderId,
                            speed: position.coords.speed,
                            version: appVersion,
                            wrongRouteStopUploadCount: wrongRouteStopList?.length ?? 0
                        };

                        return $http.post(serviceUrls.healthInfo, healthInfo);
                    }
                })
                .then(function () {
                    deferred.resolve();
                })
                .catch(function() {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function getLocalStorageSizes() {
            const deferred = $q.defer();

            const sizes = {};
            let total = 0;

            cacheService.iterate(function (value, key) {
                    const size = JSON.stringify(value).length * 2 / 1024 / 1024;
                    total += size;
                    sizes[key] = size;
                    return;
                })
                .then(function () {
                    const result = { total: total, detailed: JSON.stringify(sizes) };
                    deferred.resolve(result);
                });

            return deferred.promise;
        }

        function startExpiredWrongRouteStopDeletionPolling(){
            if (polling) return;
            polling = pollingService.startPolling(appSettings.expiredWrongRouteStopDeletionPollingIntervalMs, function(){
                removeExpiredWrongRouteStops()
                .then(function(){
                })
                .catch(function(){
                });
            }, true);
        }

        function stopExpiredWrongRouteStopDeletionPolling(){
            if (!polling) return;
            if (polling) {
                pollingService.stopPolling(polling);
                polling = null;
            }
        }

        function handleAddNewWrongRouteStopUploadRequest(routeLineId) {
            addNewWrongRouteStop(routeLineId).then(function () {
                console.log("wrong routestop added to cache with routeLineId: " + routeLineId);
            });
        }

        function getWrongRouteStops(){
            return cacheService.get(cacheKey);
        }
      
        function addNewWrongRouteStop(routeLineId){
            const deferred = $q.defer();
            if (!routeLineId) {
                deferred.reject();
                return deferred.promise;
            } 
            getWrongRouteStops()
                .then(function (wrongRouteStops) {
                        const isAlreadyInCache = wrongRouteStops ? _.find(wrongRouteStops, { routeLineId: routeLineId }) : false;
                        if (!isAlreadyInCache) {
                            const newCacheValue = {routeLineId: routeLineId, expirationUTC: moment().utc().add(appSettings.wrongRouteStopUploadRequestExpireMs, 'ms').format()};
                            cacheService.addTo(cacheKey, newCacheValue, false);
                            deferred.resolve();
                        }
                        else {
                            deferred.reject();
                        }
                })
                .catch(function () {
                    deferred.reject();
                });
            return deferred.promise;
        }

        function removeExpiredWrongRouteStops(){
            const deferred = $q.defer();

            getWrongRouteStops()
            .then(wrongRouteStops => {
                if (wrongRouteStops) {

                    const actualDateTimeUTC = moment().utc().toISOString();
                    cacheService.removeFromBy(cacheKey, null, 'expirationUTC', actualDateTimeUTC, 'lt').then(function () {
                        deferred.resolve();
                    }, function () {
                        deferred.reject();
                    });
                } else {
                    deferred.reject();
                }
            });

            return deferred.promise;
        }
    }
})();
