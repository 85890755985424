(function() {
    angular.module('PWAPoCApp').controller('UnitLevelDataButtonModalController', unitLevelDataButtonModalController);

    unitLevelDataButtonModalController.$inject = ['$timeout', '$scope', '$windowInstance', 'commonUtil', 'routeStop', 'dataButton'];

    function unitLevelDataButtonModalController($timeout, $scope, $windowInstance, commonUtil, routeStop, dataButton) {
        $scope.numbers = '';
        $scope.unitNumbersKeyname = `${dataButton.valueENG.toLowerCase()}Numbers`
        
        $scope.addComma = addComma;
        $scope.close = close;
        $scope.save = save;
        $scope.togglePopupVisible = togglePopupVisible;
        $scope.units = _.map(routeStop.units,
            function(u) {
                return {
                    agreementLineIds: u.agreementLines.join(','),
                    unitName: u.address + ', ' + u.name,
                    [$scope.unitNumbersKeyname]: u[$scope.unitNumbersKeyname]
                }
            });
        
        $scope.selectedUnit = {};
        var sumValues = '';
        var previousSelectedUnit = null;

        initController();

        $scope.unitOptions = {
            dataSource: {
                transport: {
                    read: readUnits
                }
            },
            dataTextField: 'unitName',
            dataValueField: 'agreementLineIds',
            optionLabel: ' ',
            change: selectionChanged
        };

        $scope.loadNumberValueFromUnit = function (unit) {
            if (!unit || !unit.agreementLineIds || unit.agreementLineIds.length === 0) {
                $scope.numbers = sumValues;
            } else {
                $scope.numbers = unit[$scope.unitNumbersKeyname] ? unit[$scope.unitNumbersKeyname] : '';
            }
        }

        $scope.storeNumberValueToUnit = function (unit) {
            if (!unit || !unit.agreementLineIds || unit.agreementLineIds.length === 0) {
                sumValues = $scope.numbers;
            } else {
                var val = commonUtil.correctDataButtonValueFloatFormat($scope.numbers);
                unit[$scope.unitNumbersKeyname] = val;
            }
        }

        function selectionChanged(e) {
            var index = e.sender.selectedIndex;
            var unit = index > 0 ? $scope.units[index - 1] : {};
            $scope.storeNumberValueToUnit(previousSelectedUnit);
            previousSelectedUnit = unit;
            $scope.loadNumberValueFromUnit(unit); 
        }


        function readUnits(e) {
            e.success($scope.units);
        }

        function togglePopupVisible(unit)
        {
            $scope.selectedUnit = unit;
            $scope.popupVisible = !$scope.popupVisible;
        }

        function initController() {
            if (dataButton.value) {
                $scope.numbers = dataButton.value;
            }
        }

        function addComma() {
            if ($scope.numbers.indexOf(',') === -1) {
                $scope.numbers += ',';
            }
        }

        function close() {
            $windowInstance.close({
                action: 'cancel',
                value: null
            });
        }

        function save() {
            const unitsWithNumbers = _.filter($scope.units, u => !!u[$scope.unitNumbersKeyname]);
            const activeUnit = _.find($scope.units, u => u.agreementLineIds === $scope.selectedUnit.agreementLineIds);
            const isAnyUnitEdited = unitsWithNumbers?.length > 0 || activeUnit;
            const unitLevelValues = isAnyUnitEdited
                ? _.map($scope.units, (u) => ({
                      agreementLineIds: u.agreementLineIds,
                      value:
                          u === activeUnit
                              ? $scope.numbers
                              : u[$scope.unitNumbersKeyname],
                  }))
                : [{ agreementLineIds: "", value: $scope.numbers }];

            $windowInstance.close({
                action: 'save',
                value: { isAnyUnitEdited, unitLevelValues },
                routeStop: routeStop
            });
        }
    }
})();
