(function () {
    angular.module('PWAPoCApp').controller('qrCodeModalController', qrCodeModalController);

    qrCodeModalController.$inject = [
        '$timeout',
        '$rootScope',
        '$scope',
        '$windowInstance',
        'settingsService'
    ];

    function qrCodeModalController(
        $timeout,
        $rootScope,
        $scope,
        $windowInstance,
        settingsService
    ) {
        const fallbackRegex = /[0-9]{4,10}/;

        $scope.stopRecording = false;
        $scope.showCameraFeed = false;
        $scope.displayMessage = $rootScope.translate('routeStopCard.deviations.startCamera');

        $timeout(250).then(initController);

        function initController() {
            settingsService.getQRCodeRegex().then((qrCodeRegex) => {
                $scope.qrCodeRegex = new RegExp(qrCodeRegex || fallbackRegex);

                $rootScope.video = document.createElement('video');
                $scope.canvasElement = document.getElementById('qr-code-canvas');
                $scope.canvas = $scope.canvasElement.getContext('2d');

                navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(function (stream) {
                    $scope.showCameraFeed = true;

                    $rootScope.video.srcObject = stream;
                    $rootScope.video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
                    $rootScope.video.play();

                    requestAnimationFrame(updateCanvas);
                }).catch(error => {
                    if (error.message === 'Could not start video source') {
                        $scope.displayMessage = $rootScope.translate('routeStopCard.deviations.cameraIsNotAvailable');
                    }
                });
            });
        }

        function updateCanvas() {
            if ($rootScope.video && $rootScope.video.readyState === $rootScope.video.HAVE_ENOUGH_DATA) {
                $scope.canvasElement.hidden = false;
                $scope.canvasElement.height = $rootScope.video.videoHeight;
                $scope.canvasElement.width = $rootScope.video.videoWidth;
                $scope.canvas.drawImage($rootScope.video, 0, 0, $scope.canvasElement.width, $scope.canvasElement.height);

                var imageData = $scope.canvas.getImageData(0, 0, $scope.canvasElement.width, $scope.canvasElement.height);
                var code = jsQR(imageData.data, imageData.width, imageData.height, {
                    inversionAttempts: "dontInvert",
                });

                if (code) {
                    drawLine($scope.canvas, code.location.topLeftCorner, code.location.topRightCorner, "#ff3b58");
                    drawLine($scope.canvas, code.location.topRightCorner, code.location.bottomRightCorner, "#ff3b58");
                    drawLine($scope.canvas, code.location.bottomRightCorner, code.location.bottomLeftCorner, "#ff3b58");
                    drawLine($scope.canvas, code.location.bottomLeftCorner, code.location.topLeftCorner, "#ff3b58");

                    if (code.data) {
                        var regexMatch = $scope.qrCodeRegex.exec(code.data);
                        if (regexMatch && regexMatch.length > 0) {
                            stopRecording();

                            let code = regexMatch[0];
                            $timeout(250).then(() => save(code));
                        }
                    }
                }
            }

            if (!$scope.stopRecording) {
                requestAnimationFrame(updateCanvas);
            }
        }

        function save(code) {
            stopRecording();
            $windowInstance.close(code);
        }

        //Helper functions
        function drawLine(canvas, begin, end, color) {
            canvas.beginPath();
            canvas.moveTo(begin.x, begin.y);
            canvas.lineTo(end.x, end.y);
            canvas.lineWidth = 4;
            canvas.strokeStyle = color;
            canvas.stroke();
        }

        function getUrl(urlString) {
            try {
                return new URL(urlString);
            } catch (_) {
                return null;
            }
        }

        function stopRecording() {
            $scope.stopRecording = true;
            if ($rootScope.video && $rootScope.video.srcObject && $rootScope.video.srcObject.getTracks()) {
                $rootScope.video.srcObject.getTracks().forEach(track => track.stop());
                delete $rootScope.video;
            }
        }
    }
})();
