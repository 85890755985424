(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('cacheService', cacheService);

    cacheService.$inject = ['$q', '$localForage', 'authService'];

    function cacheService($q, $localForage, authService) {

        var cacheLocks = {};
        var cacheCalls = {};

        var cacheService = {
            addTo: addTo,
            addToIn: addToIn,
            appendTo: appendTo,
            clear: clear,
            get: get,
            getAsObj: getAsObj,
            getAll: getAll,
            getByPrefix: getByPrefix,
            has: has,
            iterate: iterate,
            migrate: migrate,
            prependTo: prependTo,
            remove: remove,
            removeFrom: removeFrom,
            removeFromBy: removeFromBy,
            replaceIn: replaceIn,
            set: set,
            getAllKeysWithoutUserPrefix: getAllKeysWithoutUserPrefix,
            updateServiceWorker: updateServiceWorker,
            removeFromWithCallback: removeFromWithCallback,
        };

        return cacheService;

        function getAllKeysWithoutUserPrefix() {
            var deferred = $q.defer();

            var cacheKeys = [];
            $localForage.keys()
                .then(function (keys) {
                    cacheKeys = keys;
                    return $localForage.getItem(keys);
                })
                .then(function (cacheItems) {
                    var cacheItemsWithKeys = [];

                    _.forEach(cacheKeys, (key, index) => {
                        cacheItemsWithKeys.push({
                            key: key,
                            value: cacheItems[index]
                        });
                    })
                    deferred.resolve(cacheItemsWithKeys);
                })
                .catch(function (error) {
                    throw new Error(error);
                });

            return deferred.promise;
        }

        function appendTo(key, item) {
            var deferred = $q.defer();

            addTo(key, item, false).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function clear() {
            var deferred = $q.defer();

            $localForage.clear().then(function () {
                deferred.resolve();
            }, function (error) {
                throw new Error(error);
            });

            return deferred.promise;
        }

        function get(key) {
            var deferred = $q.defer();

            getCompositeKey(key)
                .then(function (compositeKey) {
                    return $localForage.getItem(compositeKey);
                })
                .then(function (cacheItem) {
                    var item = cacheItem ? cacheItem.data : null;
                    deferred.resolve(item);
                })
                .catch(function (error) {
                    throw new Error(error);
                });

            return deferred.promise;
        }

        function getAsObj(key) {
            var deferred = $q.defer();
            get(key)
                .then((value) => {
                    deferred.resolve({
                        key,
                        value
                    });
                });
            return deferred.promise;
        }

        function getAll() {
            var deferred = $q.defer();

            getByPrefix('').then(function (matches) {
                deferred.resolve(matches);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getByPrefix(prefix) {
            var deferred = $q.defer();

            var keyPrefix;
            var matchedKeys = [];

            getCompositeKey(prefix)
                .then(function (compositePrefix) {
                    keyPrefix = compositePrefix;
                    return $localForage.keys();
                })
                .then(function (keys) {
                    matchedKeys = _.filter(keys, function (key) {
                        return _.startsWith(key, keyPrefix);
                    });

                    return $localForage.getItem(matchedKeys);
                })
                .then(function (cacheItems) {
                    var matches = {};

                    _.forEach(matchedKeys, function (matchedKey, index) {
                        var pattern = new RegExp('^(' + keyPrefix + ')', 'g');
                        var matchKey = _.replace(matchedKey, pattern, '');
                        matches[matchKey] = cacheItems[index].data;
                    });

                    deferred.resolve(matches);
                })
                .catch(function (error) {
                    throw new Error(error);
                });

            return deferred.promise;
        }

        function has(key) {
            var deferred = $q.defer();

            cacheService.get(key).then(function (item) {
                var has = item !== undefined && item !== null;
                deferred.resolve(has);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function iterate(iteratorCallback) {
            return $localForage.iterate(iteratorCallback);
        }

        function migrate() {
            var deferred = $q.defer();

            var localStorageInstance = $localForage.createInstance({
                name: 'lf',
                driver: localforage.LOCALSTORAGE
            });

            var migrationNeeded = true;
            localStorageInstance.getItem('migrated')
                .then(function (item) {
                    migrationNeeded = item === null;
                    if (migrationNeeded) {
                        return localStorageInstance.iterate(function (value, key) {
                            $localForage.setItem(key, value);
                        });
                    }
                })
                .then(function () {
                    if (migrationNeeded) return localStorageInstance.clear();
                })
                .then(function () {
                    if (migrationNeeded) return localStorageInstance.setItem('migrated', moment());
                })
                .then(function () {
                    deferred.resolve();
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function prependTo(key, item) {
            var deferred = $q.defer();

            addTo(key, item, true).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function remove(key) {
            var deferred = $q.defer();

            getCompositeKey(key)
                .then(function (compositeKey) {
                    return $localForage.removeItem(compositeKey);
                })
                .then(function () {
                    deferred.resolve();
                })
                .catch(function (error) {
                    throw new Error(error);
                });

            return deferred.promise;
        }

        function removeFromWithCallback(key, functionName, args, callback) {
            return lockingFunction(key, functionName, args, callback);
        }

        function removeFromBy(key, nestedKey, filterKey, filterValue, operator) {
            var deferred = $q.defer();

            lockingFunction(key, 'removeFromBy', arguments, function (value) {
                var array = nestedKey ? (value ? value[nestedKey] : null) : (value || null);
                if (!_.isArray(array)) throw Error('Cannot replace item in non-array cache item.');

                _.remove(array, function (item) {
                    if (!filterKey)
                        return item === filterValue;
                    else if (operator == 'eq')
                        return item[filterKey] === filterValue;
                    else if (operator == 'gt')
                        return item[filterKey] > filterValue;
                    else if (operator == 'lt')
                        return item[filterKey] < filterValue;
                    else if (operator == 'gteq')
                        return item[filterKey] >= filterValue;
                    else if (operator == 'lteq')
                        return item[filterKey] <= filterValue;
                    else if (operator == 'in')
                        return _.includes(item[filterKey], filterValue);
                    else
                        return false;
                });

                return value;
            }).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function removeFrom(key, nestedKey, filterKey, filterValue) {
            var deferred = $q.defer();

            removeFromBy(key, nestedKey, filterKey, filterValue, 'eq').then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function replaceIn(key, nestedKey, item, filterKey, filterValue) {
            var deferred = $q.defer();

            lockingFunction(key, 'replaceIn', arguments, function (value) {
                var array = nestedKey ? (value ? value[nestedKey] : null) : (value || null);
                if (!_.isArray(array)) throw Error('Cannot replace item in non-array cache item.');

                var itemIndex = _.findIndex(array, function (item) {
                    return _.get(item, filterKey) == filterValue;
                });
                array.splice(itemIndex, 1, item);

                return value;
            }).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function set(key, item) {
            var deferred = $q.defer();

            //Add caching metadata
            var cacheItem = {
                createdAt: new Date().toISOString(),
                data: removeUnstorableProperties(item)
            };

            getCompositeKey(key)
                .then(function (compositeKey) {
                    return $localForage.setItem(compositeKey, cacheItem);
                })
                .then(function () {
                    deferred.resolve();
                })
                .catch(function (error) {
                    throw new Error(error);
                });

            return deferred.promise;
        }

        //Private functions
        function lockingFunction(key, functionName, args, callback) {
            var deferred = $q.defer();

            if (!cacheLocks[key]) {
                cacheLocks[key] = true;

                get(key)
                    .then(function (value) {
                        return callback(value);
                    })
                    .then(function (newValue) {
                        if (newValue) return set(key, newValue);
                    })
                    .then(function () {
                        cacheLocks[key] = false;
                        var cacheCall = cacheCalls[key] ? cacheCalls[key].pop() : null;
                        if (cacheCall) {
                            cacheService[cacheCall.functionName].apply(null, cacheCall.args).then(function () {
                                if (cacheCall.deferred) cacheCall.deferred.resolve();
                            }, function () {
                                if (cacheCall.deferred) cacheCall.deferred.reject();
                            });
                        }

                        deferred.resolve();
                    })
                    .then(function () {
                        deferred.resolve();
                    })
                    .catch(function () {
                        cacheLocks[key] = false;
                        deferred.reject();
                    });
            } else {
                cacheCalls[key] = cacheCalls[key] || [];
                cacheCalls[key].unshift({ functionName: functionName, args: args, deferred: deferred });
            }

            return deferred.promise;
        }

        function addTo(key, item, isPrepend) {
            var deferred = $q.defer();

            lockingFunction(key, 'addTo', arguments, function (array) {
                array = array || [];
                if (!_.isArray(array)) throw Error('Cannot append to non-array cache item.');

                if (isPrepend) {
                    if (_.isArray(item)) {
                        array = _.concat(item, array);
                    } else {
                        array.unshift(item);
                    }
                } else {
                    if (_.isArray(item)) {
                        array = _.concat(array, item);
                    } else {
                        array.push(item);
                    }
                }

                return array;
            }).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function addToIn(key, item, isPrepend, nestedKey) {
            var deferred = $q.defer();

            lockingFunction(key, 'addTo', arguments, function (value) {

                var array = nestedKey ? (value ? value[nestedKey] : null) : (value || null);
                if (!_.isArray(array)) throw Error('Cannot replace item in non-array cache item.');

                if (isPrepend) {
                    if (_.isArray(item)) {
                        array = _.concat(item, array);
                    } else {
                        array.unshift(item);
                    }
                } else {
                    if (_.isArray(item)) {
                        array = _.concat(array, item);
                    } else {
                        array.push(item);
                    }
                }

                return value;
            }).then(function () {
                deferred.resolve();
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getCompositeKey(itemKey) {
            var deferred = $q.defer();

            authService.getAuthData().then(function (authData) {
                var compositeKey = authData.username + "_" + itemKey;
                deferred.resolve(compositeKey);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function removeUnstorableProperties(object) {
            if (_.isArray(object)) {
                return _.map(object, removeUnstorableProperties);
            } else if (_.isObject(object)) {
                _.forIn(object, function (value, key) {
                    if (_.isArray(value)) {
                        object[key] = removeUnstorableProperties(value);
                    }
                });

                return _.pickBy(object, isStorable);
            } else {
                return object;
            }
        }

        function isStorable(value) {
            return !_.isFunction(value);
        }

        function updateServiceWorker() {
            if (!('serviceWorker' in navigator)) return;

            navigator.serviceWorker.register('/service-worker.js')
            .then(function (registration) {
                return registration.unregister();
            })
            .then(function(success){
                if (!success) throw Error('Unable to unregister service worker.');
                console.log('ServiceWorker registration complete.');
            })
            .catch(function (err) {
                console.error(err);
            })
            .finally(function () {
                location.reload();
            });
        }
    }
})();
