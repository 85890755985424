(function () {
    'use strict';

    const pwaPoCApp = angular.module('PWAPoCApp');

    pwaPoCApp.constant('norwegianTranslations', {
        general: {
            appTitle: 'DTA Universal',
            cancel: 'Avbryt',
            save: 'Lagre',
            add: 'Legg til',
            ok: 'Ok',
            close: 'Lukk',
            on: 'på',
            off: 'av',
            no: 'Nei',
            yes: 'Ja',
            followMe: 'Følg meg',
            undo: 'Angre',
            send: 'Send',
        },
        footer: {
            sort: 'Sorter',
            auto: 'Auto',
            autoModeConfirmation: 'Ønsker du å aktivere automatisk rapportering basert på din lokasjon og hastighet',
            noDeletableOrders: 'Det finnes ingen ordre som kan slettes. Kun ordre eldre enn 1 dag kan slettes.',
        },
        routeStopCard: {
            deviation: 'Avvik',
            battery: 'Batteri',
            events: 'Logg',
            navigation: 'Naviger',
            info: 'Info',
            addArticle: 'Legg til Artikkel',
            no: 'Nei',
            yes: 'Ja',
            delete: 'Fjern',
            number: 'Antall',
            product: 'Produkt',
            today: 'I dag',
            oneToThreeDays: '1-3 dager',
            fourToSixDays: '4-6 dager',
            sevenToFourteenDays: '7-14 dager',
            moreThanFourteenDays: '> 14 dager',
            goBackConfirmation: 'Er du sikker på at du vil gå tilbake? Data er ikke lagret.',
            units: 'Enheter',
            comment: 'Kommentar',
            deviationCategories: 'Avvikskategorier',
            favorites: 'Favoritter',
            dataRegistration: 'Dataregistering',
            details: 'Detaljer',
            navigateTo: 'Navigere til',
            done: 'UTFØRT',
            Databutton1: 'Vekt',
            Databutton2: 'Sekker',
            Databutton3: 'Fyll (%)',
            Databutton4: 'Papp-ball',
            Databutton5: 'Ekstra avfallsekk',
            orDeviationRequired: 'eller avvik er påkrevd!',
            isRequired: 'er påkrevd!',
            camera: {
                ok: 'Ok',
                close: 'Lukk',
                takePicture: 'Ta et bilde',
                retakePicture: 'Gjenta et bilde'
            },
            containerEvents: {
                view: 'Vis',
                noRecords: 'Ingen treff',
                time: 'Dato/tid',
                comment: 'Kommentar',
                user: 'Bil',
                logAction: 'Hendelse'
            },
            containerStatistics: {
                noData: 'Ingen statistikk tilgjengelig',
                close: 'Lukk',
                signalStrength: 'Signalstyrke',
                battery: 'Batteri',
                temperature: 'Temperatur',
                fillLevel: 'Fyllingsgrad',
                accessCount: 'Aksesseringer',
                usage: 'Verwendungen',
                tooltipTemplate: 'Dag: #= category #<br />#= series.name #: #= value #',
            },
            deviations: {
                removeAll: 'Fjern alle',
                selectAll: 'Velg alle',
                resetSelectedOnes: 'NullStill valg',
                allEmpty: 'Alle dunker er tømt?',
                isEmpty: 'Dunk er tømt?',
                qrCode: 'QR-kode',
                comment: 'Kommentar',
                picture: 'Bilde',
                selectDeviationCategory: 'Velg Avvikskategori',
                allCategories: 'Alle kategorier',
                ok: 'Ok',
                resetConfirmation: 'Ønsker du å fjerne alle registreringer, kommentarer og bilder?',
                removeAllRegistrationsTitle: 'Fjerne alle registreringer?',
                pictureAndCommentRequiredWarning: 'Bilde og kommentar er påkrevd for denne typen avvik',
                commentRequiredWarning: 'Kommentar er påkrevd for denne typen avvik',
                pictureRequiredWarning: 'Bilde er påkrevd for denne typen avvik',
                unitMustBeSelectedError: 'Minst en enhet må være valgt for å legge til avvik/merknad.',
                imageListOpenError: 'Minst en enhet må være valgt for å legge til et bilde.',
                unknownError: 'Ukjent feil',
                profileInfoOfflineMsg: 'Informasjon ikke tilgjengelig uten dekning.',
                noRequiredDeviation: 'Nei krever minst et avvik',
                picture: 'Bilde',
                comment: 'Kommentar',
                qrCode: 'QR-kode',
                startCamera: 'Starter kamera',
                cameraIsNotAvailable: 'Kamera er ikke tilgjengelig. Vennligst lukk andre applikasjoner som bruker kameraet.',
                deviation: 'Avvik',
                newPicture: 'Ny Bilde',
                selectImage: 'Velg bilde',
            },
            routeStopInfo: {
                latitude: 'Breddegrad:',
                longitude: 'Lengdegrad:',
                accuracy: 'Nøyaktighet:',
                adjustPoint: 'Juster punkt',
                adjustSludgeTank: 'Juster slamtank',
                adjustFailed: 'Juster slamtank feil',
            },
            weightSensorModal: {
                waitingMsg: 'Venter på vektdata',
                connectedToSensor: 'Koblet til vekt:',
                noSensorConnected: 'Ingen vekt er tilkoblet',
                kg: 'kg',
                registerManually: 'Registrer manuelt',
                total: 'Total:'
            },
        },
        completedRouteStops: {
            number: 'Antall',
            product: 'Produkt',
            delete: 'Fjern',
            info: 'Info',
            undo: 'Angre',
            deviation: 'Avvik',
            noCompletedRoutestops: 'Ingen utførte oppdrag registrert',
            couldNotLoadCompletedRoutestops: 'Could not load completed route stops - please check your connection',
            reportHasBeenSentAlert: 'Rapporten er sendt til fagsystem og kan ikke endres.',
            routeCompletedAlert: 'Rute fullført!',
        },
        routeStopsList: {
            printing: 'Skriver ut...',
            ready: 'Klar',
            failed: 'Feil',
            messages: 'Meldinger',
            smsReceipt: 'SMS Kvittering',
            warning: 'Advarsel!',
            selectReceiptType: 'Velg kvitteringstype',
            printReceipt: 'Skriv ut kvittering',
            sendReceipt: 'Send kvittering',
            routeCompletedAlert: 'Rute fullført!',
            routeExpired: 'Rute utløpt',
            downloadFailed: 'Nedlasting feilet, men forsøk igjen. Dette kan skyldes manglende lagringsplass.',
            autoTurnedOff: 'Ved å slå av autoregistrering, vil ikke kvittering på nåværende stoppepunkt bli automatisk registrert.',
            loading: 'Laster ned...',
            retrying: ' \nRetrying...',
            scrollAlertModal: {
                dontShow: 'Ikke vis igjen',
                text1: 'Scrolling av lange lister kan medføre ytelsesproblemer',
                text2: 'Du kan vise stoppepunkt som mangler posisjonsdata ved å trykke på "Uten posisjon'
            }
        },
        nearByRouteStops: {
            distance: 'Avstand',
            offlineMessageText: 'Mangler internettdekning. Ikke mulig å laste ned nærliggende hentesteder',
            noData: 'Ingen data.',
            loading: 'Laster ned...',
        },
        login: {
            login: 'INNLOGGING',
            logIn: 'LOGG INN',
            username: 'Bruker',
            password: 'Passord',
            samsungBrowserErrorMessage: 'Samsung internett nettleser er ikke støttet, vi anbefaler at du bruker Chrome. Det kan forekomme at applikasjonen ikke fungerer som den skal i Samsung internett nettleser',
            demoModeMessage: 'Demomodus - Ikke registrert bruker for DTA Universal',
            incorrectLoginData: 'Feil brukernavn eller passord',
        },
        orderMenu: {
            logout: 'Logg ut',
            cannotLogOut: 'Kan ikke logge ut offline!',
            support: 'Support',
            settings: 'Innstillinger',
        },
        inRouteMenu: {
            cancelOrder: 'Avbryt ordre',
            workList: 'Arbeidsliste',
            undo: 'Angre',
            adhoc: 'Tilf. registrering',
            support: 'Support',
            fontSize: 'Skriftstørrelse:',
            normal: 'Normal',
            bigger: 'Større',
            biggest: 'Størst',
            speed: 'Hastighet (automatisk rapportering):',
            detection: 'Deteksjonsfilter:',
        },
        articles: {
            offlineMessageText: 'Mangler internettdekning.',
            number: 'Antall',
        },
        callOrder: {
            general: 'Generelt',
            units: 'Enheter',
            details: 'Detaljer',
            refresh: 'Oppdater',
            addSelected: 'Overfør',
        },
        orders: {
            selectDriver: 'Velg sjåfør',
            selectDriverErrorMessage: 'Velg sjåfør før rute kan lastes',
            samsungBrowserErrorMessage: 'Samsung internett nettleser er ikke støttet, vi anbefaler at du bruker Chrome. Det kan forekomme at applikasjonen ikke fungerer som den skal i Samsung internett nettleser',
            checkOutOrderErrorMessage: '* Cannot check out order while offline - please check your connection',
            confirmationMessage: 'Er du sikker?',
            noOrders: 'Ingen ordrer tilgjengelig',
            canNotLoadOrders: 'Kunne ikke laste ned ordrer. Sjekk om du er koblet til internett',
        },
        navbar: {
            callOrder: 'Anropsordre',
            settings: 'Innstillinger',
            messages: 'Meldinger',
            selectOrderTypes: 'Velg ordretyper',
            selectAreas: 'Velg områder',
            support: 'Support',
            nearByRouteStopRegistration: 'Tilfeldig registrering',
            cancelRouteConfirmation: 'Ønsker du virkelig å avbryte ruten?',
            demoMode: 'DEMOMODUS',
            noAreas: 'Ingen områder',
            startSequence: 'Startsekvens',
        },
        filters: {
            sequence: 'Rekkefølge',
            description: 'Adresse',
            distance: 'Avstand',
            fill: 'Fyll %',
            volt: 'Volt',
            days: 'Dager',
            containerId: 'ContainerNr',
            dateWhenFullToday: 'Prioritet: I dag',
            dateWhenFullIn3Days: 'Prioritet: 1-3 dager',
            orderData: {
                DetailData_Expires: 'Utføres innen',
            }
        },
        settings: {
            dataDeleteConfirmation: 'Er du sikkert på at du ønsker å slette lokalt lagrede data?',
            loggedInUser: 'Innlogget bruker',
            clearCache: 'Cache clear',
            refreshSettings: 'Oppdater innstillinger',
            settingsUpdated: 'Innstillinger oppdatert',
        },
        support: {
            happyToHelp: 'Vi hjelper deg gjerne. Ta kontakt på:',
            phone: 'Telefon',
            email: 'Epost',
            sendReport: 'Send rapport',
            openDiagnostics: 'Open diagnostics',
            diagnosticsPanel: "Diagnostics panel",
            send: 'Send',
            message: 'Beskdjed:',
            reportSent: 'Rapport sendt!',
            reportError: 'Error occured - report not sent!',
            messageRequiredError: 'Please provide a short message!',
        },
        diagnostics: {
            hi: 'Hi,',
            allRecords: 'All records',
            copied: 'Copied',
            copyIndexDB: 'Copy IndexedDB',
            copyRow: 'Copy row',
            downloadRow: 'Download row',
            downloadIndexDB: 'Download IndexedDB',
            downloading: 'Downloading...',
            noSelectedRecordMessage: 'Please select a key',
            key: 'Key',
            value: 'Value',
            beCareful: 'Be careful!',
            warningAllDataWillBeCleared: 'All current cache data will be cleared!',
            warningLoggedIn: 'Be logged in with the user that was in use when the file was exported!',
            warningUploadOnly: 'Upload only files that were downloaded in the preview step as ,,Download IndexedDB"!',
            errorCouldNotOpen: 'Could not open current IndexedDB!',
            errorCouldNotRewrite: 'Could not rewrite the IndexedDB! Please log out and log in again!',
            errorReadingFile: 'Error while reading the file!',
            errorInvalidData: 'Invalid input data',
            successfullyImported: 'Succesfully imported!',
            confirm: 'Are you sure?',
            uploadIndexDB: 'Upload IndexedDB',
        },
        errors: {
            browserError: 'Fullversjon av PWA krever en annen nettleser.',
            error_template: 'Ugyldig mal',
            error_userData: 'Ugyldig brukerdata',
            error_adjustVerticalSpacing: 'Tilpass vertikal skrift',
            error_createReceipt: 'Opprett utskrift',
            error_connectingPrinter: 'Kobler til printer',
            error_printerUnavailable: 'Printeren er utilgjengelig',
            error_sendingData: 'Sender data',
            error_establishingConnection: ' Etablerer kobling',
            error_printerIsBusy: 'Printeren er opptatt',
            error_addressError: ' Adressefeil',
            error_fillingTemplateData: 'Fyller maldata',
        },
        info: {
            seq: 'Sek',
            units: 'Enheter',
            address: 'Adresse',
            distance: 'Avstand',
            status: 'Status',
            done: 'Utført',
        },
        directMessage: {
            address: 'Adresse',
            sender: 'Avsender',
            date: 'Dato',
            mobileNumber: 'Mobilnummer',
            mobileNumberIsRequiredError: 'Mobilnummer er påkrevd!',
            mobileNumberValidationError: 'Minimum 8 og maks 10 sifre (\'+\' tegn er valgfritt)!',
            receiptText: 'Kvitteringstekst',
            sendReceipt: ' Send kvittering',
            messages: 'Meldinger',
            ok: ' Ok',
        },
        multipleChoice: {
            choodeAnAlternative: 'Velg et alternativ'
        }
    });

})();
