(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('routeStopUtils', routeStopUtils);

    routeStopUtils.$inject = ['routeStopConstants'];

    function routeStopUtils(routeStopConstants) {
        const service = {
            getWasteClass
        };

        return service;

        function getWasteClass(wasteType, system = '') {
            const { types: wasteTypes , classes: wasteClasses  } = routeStopConstants.wasteType;
            const { system: systems } = routeStopConstants;
            const lowerCaseWasteType = wasteType ? wasteType.toLowerCase() : wasteTypes.household;
            
            switch (lowerCaseWasteType) {
                case wasteTypes.household:
                    return wasteClasses.household;
                case wasteTypes.commercial:
                    switch (system.toLowerCase()) {
                        case systems.H.toLowerCase():
                            return wasteClasses.altCommercial;
                        default:
                            return wasteClasses.commercial;
                    }
                case wasteTypes.sludge:
                    return wasteClasses.sludge;
                case wasteTypes.cabin:
                    return wasteClasses.cabin;
                default:
                    return wasteClasses.household;
            }
        };
    }
})();
