(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('pollingService', pollingService);

    pollingService.$inject = [
        '$interval',
    ];

    function pollingService(
        $interval,
    ) {

        /**
         * 
         * @param {number} intervalMs 
         * @param {function} callback 
         * @returns {object}
         */
        const startPolling = (intervalMs, callback) => {
            const timer = setInterval(callback, intervalMs);
            return timer;
        };

        const stopPolling = (timer) => {
            clearInterval(timer);
        };

        const pollingService = {
            startPolling,
            stopPolling,
        };
        
        return pollingService;

    }
})();
