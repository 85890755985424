(function () {
    "use strict";

    angular
        .module("PWAPoCApp")
        .controller("SupportController", supportController);

    supportController.$inject = ["$scope", "$kWindow", 'translateService'];

    function supportController($scope, $kWindow, translateService) {
        $scope.sendReport = sendReport;
        $scope.openDiagPanel = openDiagPanel;
        $scope.translate = translateService.translate;

        function sendReport() {
            $kWindow.open({
                options: {
                    modal: true,
                    title: translateService.translate('support.sendReport'),
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    height: 250,
                    width: 350,
                },
                templateUrl: "app/support/send-report-view.html",
                windowTemplateUrl: "app/shared/modal-base.html",
                controller: "SendReportController",
            });
        }

        function openDiagPanel() {
            $kWindow.open({
                options: {
                    modal: true,
                    title: translateService.translate('support.diagnosticsPanel'),
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    height: "100%",
                    width: "100%",
                },
                templateUrl: "app/diagnostics/diagnostics-modal-view.html",
                windowTemplateUrl: "app/shared/modal-base.html",
                controller: "diagnosticsModalController",
            });
        }
    }
})();
