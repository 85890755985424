(function () {
    'use strict';

    angular.module('PWAPoCApp').controller('InfoController', infoController);

    infoController.$inject = ['$scope', '$state', 'translateService'];

    function infoController($scope, $state, translateService) {
        $scope.visibility = {
            routeStops: false
        };
        $scope.translate = translateService.translate;

        initController();

        function initController() {
            initVisibility($state.current.name);

            $scope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
                initVisibility(toState.name);
            });
        }

        function initVisibility(stateName) {
            clearVisibility();

            if (stateName === 'main.routeStops') {
                $scope.visibility.routeStops = true;
            } else if(stateName === 'main.completedRouteStops') {
                $scope.visibility.completedRouteStops = true;
            }
        }

        function clearVisibility() {
            for (var visible in $scope.visibility) {
                if ($scope.visibility.hasOwnProperty(visible)) {
                    $scope.visibility[visible] = false;
                }
            }
        }
    }
})();
