(function () {
    'use strict';

    let pwaPoCApp = angular.module('PWAPoCApp');

    pwaPoCApp.run(updateActionTypeSetup);

    updateActionTypeSetup.$inject = [
        '$q',
        'routeStopsService',
        'ordersService',
        'messagesService',
        'imageService',
        'updateQueue',
        'deviationService'
    ];

    function updateActionTypeSetup(
        $q,
        routeStopsService,
        ordersService,
        messagesService,
        imageService,
        updateQueue,
        deviationService
    ) {
        let updateActionTypes = [
            {
                type: 'updateRouteStop',
                callback: routeStopsService.updateRouteStopRequest
            },
            {
                type: 'bulkUpdateRouteStops',
                callback: routeStopsService.bulkUpdateRouteStopRequests
            },
            {
                type: 'updateOrderStatus',
                callback: ordersService.updateOrderStatusRequest
            },
            {
                type: 'deleteRoute',
                callback: routeStopsService.deleteRouteRequest
            },
            {
                type: 'deleteOrder',
                callback: ordersService.deleteOrderRequest
            },
            {
                type: 'updateMessageStatus',
                callback: messagesService.updateMessageStatusRequest
            },
            {
                type: 'sendMessage',
                callback: messagesService.sendMessageRequest
            },
            {
                type: 'uploadImage',
                callback: uploadImageRequest
            }
        ];

        updateQueue.setUpdateActionTypes(updateActionTypes);

        async function uploadImageRequest(orderId, routeLineId, agreementLineIds, imageHash) {
            try {
              const cacheImage = await deviationService.getImageFromCache(orderId, agreementLineIds, imageHash);
          
              const pictureUrl = cacheImage?.pictureUrl
                ? cacheImage.pictureUrl
                : await imageService.uploadImage(imageHash);
          
              await deviationService.removeImageFromCacheByTimeStamp(orderId, cacheImage?.timestamp);
              await deviationService.saveImageToCache(orderId, routeLineId, agreementLineIds, imageHash, pictureUrl);
          
              const routeStop = await routeStopsService.getLocalRouteStop(orderId, routeLineId);
          
              if (!routeStop) {
                throw new Error(`OrderId: ${orderId}, routeLineId: ${routeLineId} routestop was not found`);
              }
          
              agreementLineIds.forEach(agreementLineId => {
                const isFound = routeStop.deviationPictures.some(
                  deviationPicture =>
                    deviationPicture.agreementLineId === agreementLineId && deviationPicture.pictureUrl === pictureUrl
                );
          
                if (!isFound) {
                  routeStop.deviationPictures.push({ agreementLineId, pictureUrl });
                }
              });
          
              await routeStopsService.saveLocalRouteStop(orderId, routeStop);
              return;
            } catch (err) {
              console.error(err);
              throw err;
            }
        }       
    }
})();
