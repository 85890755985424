(function () {
    'use strict';

    angular.module('PWAPoCApp').directive('routeStopRowButtonsProfile01', routeStopRowButtonsProfile01);

    routeStopRowButtonsProfile01.$inject = ['routeStopsService', 'orderDataKeys', 'translateService'];

    function routeStopRowButtonsProfile01(routeStopsService, orderDataKeys, translateService) {
        var directive = {
            restrict: 'E',
            replace: true,
            templateUrl: 'app/shared/directives/routeStopCard/route-stop-row-buttons-profile01.html',
            transclude: true,
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            scope.translate = translateService.translate;
            scope.infoCarousel = {
                infoOnKeys: {
                    index: 0,
                },
                latestDeviations: {
                    index: 0,
                }
            };
            scope.handleInfoPanelArrayStepped = function(evt, direction, type, routeStop) {
                preventPropagation(evt);
                switch (direction) {
                    case 'backward':
                        scope.infoCarousel[type].index = scope.infoCarousel[type]?.index > 0 ? scope.infoCarousel[type]?.index - 1 : routeStop.infoCarousel[type].length - 1;
                        break;

                    case 'forward':
                        scope.infoCarousel[type].index = scope.infoCarousel[type].index < routeStop.infoCarousel[type].length - 1 ? scope.infoCarousel[type].index + 1 : 0;
                        break;
                
                    default:
                        break;
                }
            }

            function preventPropagation(evt) {
                if (evt) {
                    evt.stopPropagation();
                    evt.preventDefault();
                }
            }
        }
    }
})();