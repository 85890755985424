(function() {
    'use strict';

    angular.module('PWAPoCApp').factory('settingsService', settingsService);

    settingsService.$inject = ['$q', '$http', '$log', 'cacheService', 'appVersion', 'appSettings', 'settingsMetadata', 'settingMetadataType'];

    function settingsService($q, $http, $log, cacheService, appVersion, appSettings, settingsMetadata, settingMetadataType) {
        var cachePrefix = '_settings_';
        var userSettingsCacheKey = cachePrefix + 'usersetting';
        var vehicleSettingsCacheKey = cachePrefix + 'vehiclesetting';
        var activeDriverKey = 'activeDriver';

        var service = {
            getCachedDirectMessages: getCachedDirectMessages,
            getCallOrderCheckInterval: getCallOrderCheckInterval,
            getCategories: getCategories,
            getDeviations: getDeviations,
            getDriverLanguage: getDriverLanguage,
            getSettings: getSettings,
            getUserType: getUserType,
            getVehicleSettings: getVehicleSettings,
            getIsDataButtonsEnabled: getIsDataButtonsEnabled,
            getDataButtonValidationRules: getDataButtonValidationRules,
            getVoltAdjustUrl: getVoltAdjustUrl,
            getIsOrderTypeIconVisible: getIsOrderTypeIconVisible,
            getException1ButtonVisible: getException1ButtonVisible,
            getAutoModeToggleButtonVisible: getAutoModeToggleButtonVisible,
            getIsWeightUnitBotek: getIsWeightUnitBotek,
            getNoButtonRequiresDeviation: getNoButtonRequiresDeviation,
            getDriverList: getDriverList,
            setDriver: setDriver,
            getActiveDriver: getActiveDriver,
            getBTAdapterName: getBTAdapterName,
            getBTPrinterNames: getBTPrinterNames,
            clearActiveDriver: clearActiveDriver,
            getIsAjourUser: getIsAjourUser,
            getLoadProfileInfoData: getLoadProfileInfoData,
            getPrinterEnabled: getPrinterEnabled,
            getDisableAnimation: getDisableAnimation,
            getEnableWeight: getEnableWeight,
            getServerAppVersion: getServerAppVersion,
            getEnableTracing: getEnableTracing,
            getAccuracyThreshold: getAccuracyThreshold,
            getSmsReceiptEnabled: getSmsReceiptEnabled,
            getSmsSenderInfo: getSmsSenderInfo,
            getQRCodeRegex: getQRCodeRegex,
            getOne: getOne,
            getAll: getAll,
            getListOf: getListOf,
        };

        return service;

        function getEnableTracing() {
            return getBooleanSettingValue('EnableTracing', false);
        }

        function getEnableWeight() {
            return getBooleanSettingValue('EnableWeight', false);
        }

        function getDisableAnimation() {
            return getBooleanSettingValue('DisableAnimation', false);
        }

        function getIsAjourUser() {
            return getBooleanSettingValue('IsAjour', false);
        }

        function getPrinterEnabled() {
            return getBooleanSettingValue('EnablePrinter', false);
        }

        function getLoadProfileInfoData() {
            return getBooleanSettingValue('LoadProfileInfoData', false);
        }

        function getIsOrderTypeIconVisible() {
            return getBooleanSettingValue('IsOrderTypeIconVisible', false);
        }

        function getAutoModeToggleButtonVisible() {
            return getBooleanSettingValue('AutoModeToggleButtonVisible', true);
        }

        function getException1ButtonVisible() {
            return getBooleanSettingValue('Exception1ButtonVisible', false);
        }

        function getIsDataButtonsEnabled() {
            return getBooleanSettingValue('IsDataButtonsEnabled', true);
        }

        function getDataButtonValidationRules() {
            return $q.all([
                getStringSettingValue('ValidationDataButton1RequiredForProfilType'),
                getBooleanSettingValue('ValidationDataButton1RequiredOrDeviation', false),
                getStringSettingValue('ValidationDataButton2RequiredForProfilType'),
                getBooleanSettingValue('ValidationDataButton2RequiredOrDeviation', false),
                getStringSettingValue('ValidationDataButton3RequiredForProfilType'),
                getBooleanSettingValue('ValidationDataButton3RequiredOrDeviation', false),
                getStringSettingValue('ValidationDataButton4RequiredForProfilType'),
                getBooleanSettingValue('ValidationDataButton4RequiredOrDeviation', false),
                getStringSettingValue('ValidationDataButton5RequiredForProfilType'),
                getBooleanSettingValue('ValidationDataButton5RequiredOrDeviation', false)
            ]);
        }

        function getNoButtonRequiresDeviation() {
            return getBooleanSettingValue('NoButtonRequiresDeviation', false);
        }

        function getAccuracyThreshold(){
            return getIntSettingValue('AccuracyTreshold', appSettings.routeStops.list.accuracyTreshold);
        }

        function getSmsReceiptEnabled() {
            return getBooleanSettingValue('EnableSmsReceipt', false);
        }

        function getSmsSenderInfo() {
            return $q.all([
                getStringSettingValue('SmsSenderId'),
                getStringSettingValue('SmsSenderName')
            ]);
        }

        function getQRCodeRegex() {
            return getStringSettingValue('QRCodeRegex');
        }

        function getIsWeightUnitBotek() {
            var deferred = $q.defer();

            getVehicleSettings().then(function(settings) {
                var weightUnit = _.find(settings, function(e) { return e.key === 'WeightUnit' });
                if (!weightUnit || weightUnit.value !== 'BOTEKU237D') {
                    deferred.resolve(false);
                } else {
                    deferred.resolve(true);
                }
            });

            return deferred.promise;
        }

        function getCategories() {
            var deferred = $q.defer();

            getSettings().then(function(configurations) {
                deferred.resolve(configurations.categories);
            });

            return deferred.promise;
        }

        function getDeviations() {
            var deferred = $q.defer();

            getSettings().then(function(configurations) {
                deferred.resolve(configurations.messages);
            });

            return deferred.promise;
        }

        function getDriverList() {
            var deferred = $q.defer();

            getSettings().then(function(userSettings) {
                if (userSettings && userSettings.driverList) {
                    deferred.resolve(userSettings.driverList);
                }
                deferred.resolve([]);
            });

            return deferred.promise;
        }
       
        function getSettings(refreshCache) {
            var deferred = $q.defer();

            if (!refreshCache) {
                cacheService.has(userSettingsCacheKey)
                    .then(function(exists) {
                        if (exists) {
                            return cacheService.get(userSettingsCacheKey);
                        } else {
                            return getServerSettings();
                        }
                    })
                    .then(function(settings) {
                        deferred.resolve(settings);
                    })
                    .catch(function() {
                        deferred.reject();
                    });
            } else {
                getServerSettings().then(function(settings) {
                        deferred.resolve(settings);
                    },
                    function() {
                        deferred.reject();
                    });
            }

            return deferred.promise;
        }

      
        function hasTrueValue(setting){
            return setting && setting.value && setting.value.toLowerCase() === "true";
        }

        function getUserType() {
            var deferred = $q.defer();

            getVehicleSettings().then(function(settings) {
                var undergroundUserInfo = _.find(settings, { key: 'IsUndergroundUser' });
                var serviceUserInfo = _.find(settings, { key: 'IsServiceUser' });
                var ajourUserInfo = _.find(settings, { key: 'IsAjour' });

                var userType = 'default';

                if (hasTrueValue(ajourUserInfo)){
                    userType = 'ajour';
                } else if (hasTrueValue(undergroundUserInfo)){
                    userType = (hasTrueValue(serviceUserInfo)) ? 'service' : 'underground';
                }

                deferred.resolve(userType);
            });

            return deferred.promise;
        }

        function getBTAdapterName() {
            var deferred = $q.defer();

            getVehicleSettings().then(function (settings) {
                var BTAdapterName = _.find(settings, { key: 'BTAdapterName' });
                var btAdapterNameValue = !BTAdapterName ? null : BTAdapterName.value;
                deferred.resolve(btAdapterNameValue);
            });

            return deferred.promise;
        }

        function getBTPrinterNames() {
            var deferred = $q.defer();

            getVehicleSettings().then(function (settings) {
                var BTPrinterName = _.find(settings, { key: 'BTPrinterName' });
                var names = null;
                if (BTPrinterName && BTPrinterName.value)
                {
                    var names = BTPrinterName.value.split(',');
                }
                deferred.resolve(names);
            });

            return deferred.promise;
        }

        function getCachedDirectMessages() {
            const deferred = $q.defer();

            cacheService.get('_messages_')
            .then(function (messages) {
                deferred.resolve(messages);
            })
            .catch(function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getVoltAdjustUrl() {
            var deferred = $q.defer();

            getVehicleSettings().then(settings => {
                    var voltAdjustUrl = _.find(settings, { key: 'VoltAdjustUrl' });

                    if (voltAdjustUrl && voltAdjustUrl.value) {
                        deferred.resolve(voltAdjustUrl.value);
                    } else {
                        deferred.resolve(null);
                    }
                },
                deferred.reject);

            return deferred.promise;
        }

        function getCallOrderCheckInterval() {
            var deferred = $q.defer();

            getVehicleSettings().then(settings => {
                    var callOrderCheckInterval = _.find(settings, { key: 'CallOrderCheckInterval' });

                    if (callOrderCheckInterval && callOrderCheckInterval.value) {
                        var callOrderCheckIntervalValue = Number(callOrderCheckInterval.value);
                        if (callOrderCheckIntervalValue !== 0) {
                            if (callOrderCheckIntervalValue > appSettings.callOrdersMinPollingIntervalMs) {
                                deferred.resolve(callOrderCheckIntervalValue);
                            } else {
                                deferred.resolve(appSettings.callOrdersMinPollingIntervalMs);
                            }
                        }
                        deferred.resolve(null);
                    } else {
                        deferred.resolve(null);
                    }
                },
                deferred.reject);

            return deferred.promise;
        }

        function getDriverLanguage() {
            var deferred = $q.defer();

            getVehicleSettings().then(function(settings) {
                const driverLanguage = _.find(settings, { key: 'DriverLanguage' });
                const driverLanguageValue = !driverLanguage ? (appSettings.defaultLanguage || 'no') : driverLanguage.value;
                deferred.resolve(driverLanguageValue);
            });

            return deferred.promise;
        }


        function getVehicleSettings(refreshCache) {
            var deferred = $q.defer();

            if (!refreshCache) {
                cacheService.has(vehicleSettingsCacheKey)
                    .then(function(exists) {
                        if (exists) {
                            return cacheService.get(vehicleSettingsCacheKey);
                        } else {
                            return getServerVehicleSettings();
                        }
                    })
                    .then(function(settings) {
                        deferred.resolve(settings);
                    })
                    .catch(function() {
                        deferred.reject();
                    });
            } else {
                getServerVehicleSettings().then(function(settings) {
                        deferred.resolve(settings);
                    },
                    function() {
                        deferred.reject();
                    });
            }

            return deferred.promise;
        }

        function setDriver(driverId) {
            return new Promise(resolve => {
                getDriverName(driverId).then(driverName => {
                    return cacheService.set(activeDriverKey, { driverId: driverId, driverName: driverName });
                }).then(() => {
                    resolve(true);
                }).catch(() => {
                    resolve(false);
                });
            });
        }

        function getActiveDriver() {
            return cacheService.get(activeDriverKey);
        }

        function getDriverName(driverId){
            var deferred = $q.defer();

            getSettings().then(function(userSettings) {
                if (userSettings && userSettings.driverList) {
                    let drivers = _.filter(userSettings.driverList, d => d.driverId === driverId);
                    let driverName = drivers && drivers.length > 0  && drivers[0].firstName !== undefined && drivers[0].lastName !== undefined
                    ? drivers[0].firstName + " " + drivers[0].lastName 
                    : "";

                    deferred.resolve(driverName);
                }
                deferred.resolve("");
            });

            return deferred.promise;
        }

        async function clearActiveDriver() {
            return cacheService.remove(activeDriverKey);
        }

        // private functions
        function getServerSettings() {
            var deferred = $q.defer();

            var settings;
            $http.post('/api/settings', { softwareVersion: appVersion })
                .then(function(response) {
                    settings = response.data;
                    return cacheService.set(userSettingsCacheKey, settings);
                })
                .then(function() {
                    deferred.resolve(settings);
                })
                .catch(function() {
                    deferred.reject();
                });

            return deferred.promise;
        }

        function getServerVehicleSettings() {
            var deferred = $q.defer();

            var settings;
            $http.get('/api/settings/vehicle')
                .then(function(response) {
                    settings = response.data;
                    return cacheService.set(vehicleSettingsCacheKey, settings);
                })
                .then(function() {
                    deferred.resolve(settings);
                })
                .catch(function() {
                    deferred.reject();
                });

            return deferred.promise;
        }

        async function getServerAppVersion() {
            const response = await $http.get('/api/appversion');
            return response?.data;
        }

        function getBooleanSettingValue(keyName, defaultValue){
            var deferred = $q.defer();
            getVehicleSettings().then(function(settings) {
                var settingValue = _.find(settings, function (e) { return e.key === keyName });
                if (!settingValue || !settingValue.value){
                    deferred.resolve(defaultValue);
                }else{
                    if (settingValue.value.toLowerCase() === 'false') {
                        deferred.resolve(false);
                    } else {
                        deferred.resolve(true);
                    }
                }
                
            });
            
            return deferred.promise;
        }

        function getIntSettingValue(keyName, defaultValue){
            var deferred = $q.defer();
            getVehicleSettings().then(function(settings) {
                var settingValue = _.find(settings, function (e) { return e.key === keyName });
                if (settingValue && settingValue.value){
                    deferred.resolve(Number(settingValue.value));
                }else{
                    deferred.resolve(defaultValue);
                }
                
            });
            
            return deferred.promise;
        }

        function getStringSettingValue(keyName, defaultValue) {
            var deferred = $q.defer();
            getVehicleSettings().then(function (settings) {
                var settingValue = _.find(settings, function (e) { return e.key === keyName });
                if (settingValue && settingValue.value) {
                    deferred.resolve(settingValue.value);
                } else {
                    deferred.resolve(defaultValue);
                }

            });

            return deferred.promise;
        }

        //returning Array<Record<string, T>>
        function getAll() {
            const deferred = $q.defer();
            getVehicleSettings().then(function (settings) {
                const settingValues = settings.map(setting => {
                    const keyName = setting.key;
                    const metaData = _.find(settingsMetadata, function (e) { return e.key ===  keyName});
                    const settingObj = {};
                    if (!setting || !setting.value){
                        settingObj[keyName] = metaData?.defaultValue;
                    } else{
                        const convertedSettingValue = convertStringSettingToType(setting, metaData?.type ?? '');
                        settingObj[keyName] = convertedSettingValue;
                    }
                    return settingObj;
                });
                deferred.resolve(settingValues);
            });
            return deferred.promise;
        }

        //returning Array<T>
        function getListOf(keysArray) {
            const deferred = $q.defer();
            getVehicleSettings().then(function (settings) {
                const settingValues = keysArray.map(keyName => {
                    const metaData = _.find(settingsMetadata, function (e) { return e.key === keyName });
                    const settingValue = _.find(settings, function (e) { return e.key === keyName });
                    if (!settingValue || !settingValue.value){
                        return metaData?.defaultValue;
                    } else{
                        const convertedSettingValue = convertStringSettingToType(settingValue, metaData?.type ?? '');
                        return convertedSettingValue;
                    }
                });
                deferred.resolve(settingValues);
            });
            return deferred.promise;
        }

        function getOne(keyName) {
            const deferred = $q.defer();
            getVehicleSettings().then(function (settings) {
                const metaData = _.find(settingsMetadata, function (e) { return e.key === keyName });
                const settingValue = _.find(settings, function (e) { return e.key === keyName });
                if (!settingValue || !settingValue.value){
                    deferred.resolve(metaData?.defaultValue);
                } else{
                    const convertedSettingValue = convertStringSettingToType(settingValue, metaData?.type ?? '');
                    deferred.resolve(convertedSettingValue);
                }
            });

            return deferred.promise;
        }

        function convertStringSettingToType(settingValue, type) {
            switch(type) {
                case settingMetadataType.boolean:
                  return convertStringSettingToBoolean(settingValue);
                case settingMetadataType.number:
                  return convertStringSettingToNumber(settingValue);
                default:
                  return settingValue.value;
              }
        }

        function convertStringSettingToBoolean(settingValue) {
            switch (settingValue.value.toLowerCase()) {
                case 'true':
                    return true;
                case 'false':
                    return false;
                default:
                    return settingValue.value;
            }
        }

        function convertStringSettingToNumber(settingValue) {
            return Number(settingValue.value);
        }
    };
})();
