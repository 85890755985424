(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('profileInfoService', profileInfoService);

    profileInfoService.$inject = ['$q', '$http', 'cacheService'];

    var profileInfoCacheKey = "profileInfo";

    function profileInfoService($q, $http, cacheService) {
        var service = {
            deleteProfileInfoCache: deleteProfileInfoCache,
            retrieveProfileInfo: retrieveProfileInfo,
            retrieveFullProfileInfo: retrieveFullProfileInfo
        };

        return service;

        function retrieveProfileInfo(agreementLines, system, full) {
            var deferred = $q.defer();

            cacheService.get(profileInfoCacheKey)
            .then(profileInfos => {
                if (!profileInfos){
                    retrieveProfileInfoServer(agreementLines, system, profileInfos, full).then(data =>{
                        deferred.resolve(data);
                    }).catch(()=>{
                        deferred.resolve(null);
                    })
                } else {
                    var matchingInfos = _.filter(profileInfos, p=>p.agreementLines === agreementLines.join(','));
                    if (matchingInfos && matchingInfos.length > 0){
                        deferred.resolve(matchingInfos[0].data);
                    } else {
                        retrieveProfileInfoServer(agreementLines, system, profileInfos, full).then(data =>{
                            deferred.resolve(data);
                        }).catch(()=>{
                            deferred.resolve(null);
                        })
                    }
                }
            });
            return deferred.promise;
        }

        function retrieveProfileInfoServer(agreementLines, system, profileInfos, full)
        {
            var agreementLineDataList = _.map(agreementLines,
                function (line) {
                    return {
                        agreementLineId: Number(line),
                        PASystem: system
                    }
                });

            var fullSuffix = full ? '/full' : '';
            var profileInfoArray = profileInfos ? profileInfos : [];
            var deferred = $q.defer();
            $http.post('api/profileinfo' + fullSuffix, agreementLineDataList).then(function (response) {
                if (response && response.data) {
                    var newProfileInfos = profileInfoArray.concat({
                        agreementLines: agreementLines.join(','),
                        data: response.data
                    });
                    cacheService.set(profileInfoCacheKey, newProfileInfos).then(()=>{
                        deferred.resolve(response.data);
                    });
                } else {
                    deferred.reject();
                }
            }, function () {
                deferred.reject();
            });
            return deferred.promise;  
        }

        function retrieveFullProfileInfo(agreementLines, system)
        {
           return retrieveProfileInfo(agreementLines, system, true);
        }

        async function deleteProfileInfoCache(){
            return await cacheService.remove(profileInfoCacheKey);
        }
    }
})();
