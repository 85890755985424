(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('appUtils', appUtils);

    appUtils.$inject = ['$rootScope', '$state', 'authService', 'profileInfoService', 'settingsService', 'commonUtil', 'cacheService'];

    function appUtils($rootScope, $state, authService, profileInfoService, settingsService, commonUtil, cacheService) {
        const service = {
            isAppVersionUpToDate: isAppVersionUpToDate,
            logout: logout
        };

        return service;

        async function logout() {
            if (!$rootScope.isOnline) {
                alert($rootScope.translate('orderMenu.cannotLogOut'));
                return;
            }
            await profileInfoService.deleteProfileInfoCache();
            await settingsService.clearActiveDriver();
            try {
                await authService.logout();
                $rootScope.callOrderCheckInterval = null;
                $rootScope.orderDeleteMode = false;
        
                $state.go("login");
        
                commonUtil.hideModal();
                $rootScope.$broadcast('hideBusyIndicator');

            } catch (error) {
                console.log(error);
            } finally {
                cacheService.updateServiceWorker();
            }
        }

        
        async function isAppVersionUpToDate() {
            try {
                const serverAppVersion = await settingsService.getServerAppVersion();
                const { appVersion } = await authService.getAuthData();
                return serverAppVersion === appVersion;
            } catch (error) {
                console.error(error);
                return true;
            }
        }
    }
})();