(function () {
    'use strict';

    angular.module('PWAPoCApp').controller('ScrollAlertModalController', scrollAlertModalController);

    scrollAlertModalController.$inject = ['$scope', '$windowInstance'];

    function scrollAlertModalController($scope, $windowInstance) {

        $scope.hideModal = false;
 
        $scope.close = function() {
            $windowInstance.close($scope.hideModal);
        }
    }
})();
