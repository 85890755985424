(function () {
    'use strict';

    angular.module('PWAPoCApp').controller('NavbarController', navbarController);

    navbarController.$inject = [
        '$state', '$rootScope', '$scope', 'authService', 'ordersService', 'orderStatusTransitions', '$kWindow', '$window',
        'routeStopsService', 'settingsService', 'orderDetailTypesService', 'cacheService', 'errorReportService',
        'profileInfoService', 'areaService', 'callOrdersService', 'appUtils', 'commonUtil', 'translateService'
    ];

    function navbarController(
        $state, $rootScope, $scope, authService, ordersService, orderStatusTransitions, $kWindow, $window,
        routeStopsService, settingsService, orderDetailTypesService, cacheService, errorReportService,
        profileInfoService, areaService, callOrdersService, appUtils, commonUtil, translateService
    ) {
        const routeStopsCachePrefix = "_routeStops_";
        
        //Fields
        $scope.routeName = '';
        $scope.prevStateName = '';
        $scope.prevParams = {};
        $scope.state = '';

        $scope.showOrderModal = false;
        $scope.showInrouteModal = false;
        $scope.filterVisible = false;
        $scope.orderDetailTypesFilterActive = false;
        $scope.orderDetailTypesFilterVisible = false;
        $scope.orderDetailTypesFilterDisabled = true;
        $scope.isAjour = $rootScope.userType === "ajour";

        //Public functions
        $scope.back = back;
        $scope.openMenu = openMenu;
        $scope.openOrderDetailTypesFilter = openOrderDetailTypesFilter;
        $scope.openAreaSelection = openAreaSelection;
        $scope.logout = appUtils.logout;
        $scope.cancelOrder = cancelOrder;
        $scope.toggleCompleted = toggleCompleted;
        $scope.support = support;
        $scope.settings = settings;
        $scope.showNearbyRouteStops = showNearbyRouteStops;
        $scope.callOrders = callOrders;
        $scope.messages = messages;
        $scope.openSequenceStart = openSequenceStart;
        $scope.pageReset = function (){
            $state.go('main.routeStops', $state.params, {reload: true});
        }
        $scope.areaSelectionVisible = false;
        $scope.areas = [];
        $scope.selectedAreaIds = [];
        $scope.isCustomSequenceButtonVisible = false;
        $scope.translate = translateService.translate;

        settingsService
            .getCallOrderCheckInterval()
            .then(callOrderCheckInterval => $rootScope.callOrderCheckInterval = callOrderCheckInterval);

        var isOrderDetailTypeWndOpen = false;
        var orderDetailWindowInstance;
        var callOrderWindowInstance;
        var messageWindowInstance;
        var areaSelectionWindowInstance;
        var addedNearbyRouteStops = [];

        angular.element($window).bind('orientationchange', function () {
            if (orderDetailWindowInstance) {
                orderDetailWindowInstance.close(false);
            }

            if (areaSelectionWindowInstance) {
                areaSelectionWindowInstance.close(false);
            }

            if (messageWindowInstance) {
                messageWindowInstance.close(false);
            }

            if (callOrderWindowInstance) {
                callOrderWindowInstance.close(false);
            }

            $rootScope.$broadcast('closeNearbyRouteStopWindow');

        });

        initController();

        function initController() {
            $scope.$on('routeLoaded', function (eventName, routeName) {
                $scope.routeName = routeName;
            });

            $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                $scope.routeName = '';

                if ((fromState.name === 'main.routeStops' || fromState.name === 'main.completedRouteStops') && (toState.name === 'main.map' || toState.name === 'main.deviation')) {
                    $scope.prevStateName = fromState.name;
                    $scope.prevParams = fromParams;
                }

                $scope.state = $state.current.name;
                $scope.isOrdersView = $scope.state === "main.orders";

                $scope.filterVisible = $scope.state === 'main.routeStops' || $scope.state === 'main.completedRouteStops';

                settingsService.getUserType().then(function (userType) {
                    $scope.orderDetailTypesFilterVisible = $scope.filterVisible && userType === 'service';
                    $scope.areaSelectionVisible = $scope.filterVisible && (userType === 'underground' || userType === 'service');
                });

                settingsService.getIsDataButtonsEnabled().then(function (isDataButtonsEnabled) {
                    $rootScope.isDataButtonsEnabled = isDataButtonsEnabled;
                });
            });

            $scope.$on('routeStopsLoadCompleted', function () {
                $scope.orderDetailTypesFilterDisabled = false;
            });

            callOrdersService.getCallOrders(true)
                .then(callOrders => {
                    $rootScope.$applyAsync(() => {
                        $rootScope.callOrderBadgeCount = callOrders
                            && callOrders.filter(o => !o.relatedOrderId).length
                            || 0;
                    });
                });

            $scope.$on('callOrderNotification', function () {
                if (noModalsOpen() && !($scope.state === "main.orders")) {
                    callOrders();
                }
            });

            $scope.$on('routeStopsSortChanged', function (eventName, sortBy) {
                if (sortBy !== 'sequence'){
                    $scope.isCustomSequenceButtonVisible = false;
                } else {
                    $scope.isCustomSequenceButtonVisible = true;
                }
            });

            if ($rootScope.userType === 'service') {
                orderDetailTypesService.getSelectedOrderDetailTypes().then(function (selectedOrderDetailTypes) {
                    $scope.orderDetailTypesFilterActive = selectedOrderDetailTypes && selectedOrderDetailTypes.length;
                });

                $scope.$on('orderDetailTypesFilterChanged', function (eventName, filteredOrderDetailTypes) {
                    $scope.orderDetailTypesFilterActive = filteredOrderDetailTypes && filteredOrderDetailTypes.length;
                });

                $scope.$on('orderDetailTypesCountFinished', () => {
                    if (!isOrderDetailTypeWndOpen) {
                        openOrderDetailTypesFilter();
                    }
                });
            }

            if ($rootScope.userType === 'underground' || $rootScope.userType === 'service') {
                areaService.getSelectedAreas().then(selectedAreas => {
                    if (selectedAreas) {
                        $scope.selectedAreaIds = selectedAreas.map(area => area.id);
                    }
                });

                areaService.getAreas().then(areas => $scope.areas = areas);
            }
        };

        function noModalsOpen(){
            return $('.k-window-titlebar').length === 0 || 
            ($('.k-widget.k-window').length > 0 
            && _.reject(_.map($('.k-widget.k-window').toArray(), e=>$(e).css('display')), w => w==='none').length === 0);
        }

        function openMenu() {
            if ($state.current.name === 'main.orders') {
                $scope.showOrderModal = true;
            } else {
                $scope.showInrouteModal = true;
            }
        }

        function openSequenceStart(evt) {
            preventPropagation(evt);

            const title = translateService.translate('navbar.startSequence');
            const windowOptions = {
                modal: true,
                title: title, 
                draggable: false,
                movable: false,
                resizable: false,
                visible: false,
                height: 410,
                width: 400
            };

            const inputSettings = {
                identity: title,
                minValue: 0,
                maxValue: 10000,
                parameter: "Databutton2",
                required: false,
                value: "",
                valueENG: title,
                valueNO: title,
                values: null,
                value: $rootScope.customSequenceStart !== -1 ? `${$rootScope.customSequenceStart}` : '',
                commaDisabled: true
            }

            const windowInstance = $kWindow.open({
                options: windowOptions,
                templateUrl: 'app/routeStops/modals/data-button-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'DataButtonModalController',
                resolve: {
                    dataButton: function () {
                        return inputSettings;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result && result.action === 'save') {
                    $rootScope.customSequenceStart = result.value === '' ? -1 : Number(result.value);
                    if ($state && $state.current && $state.params) {
                        $state.params.customSequenceStart = $rootScope.customSequenceStart;
                        $state.go($state.current.name, $state.params, { reload: true });
                    }
                    $rootScope.$broadcast('routeStopsSortChanged', 'sequence');
                }
            });
        }

        function openOrderDetailTypesFilter() {
            if (!$scope.orderDetailTypesFilterDisabled) {
                isOrderDetailTypeWndOpen = true;
                orderDetailWindowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        title: translateService.translate('navbar.selectOrderTypes'),
                        draggable: false,
                        movable: false,
                        resizable: false,
                        visible: false,
                        height: '100%',
                        width: 400
                    },
                    templateUrl: 'app/orderDetailTypesFilter/order-detail-types-filter-view.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'OrderDetailTypesFilterController'
                });
            }
        }

        function openAreaSelection() {
            if (!$scope.areaSelectionDisabled) {
                const itemHeight = 45,
                      modalContentExt = 85;

                let modalHeight; 
                if ($scope.areas.length === 0) {
                    modalHeight = modalContentExt + 25;
                } else {
                    modalHeight = Math.min($scope.areas.length * itemHeight + modalContentExt, 600);
                }

                areaSelectionWindowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        title: translateService.translate('navbar.selectAreas'),
                        draggable: false,
                        movable: false,
                        resizable: false,
                        visible: false,
                        height: modalHeight,
                        width: 400
                    },
                    templateUrl: 'app/navbar/modals/area-selection-modal-view.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'AreaSelectionModalController',
                    resolve: {
                        areas: function () {
                            return $scope.areas;
                        },
                        selectedAreaIds: function () {
                            return $scope.selectedAreaIds;
                        }
                    }
                });

                areaSelectionWindowInstance.result
                    .then(selectedAreaIds => {
                        if (selectedAreaIds && _.isArray(selectedAreaIds)
                            && !_.isEqual(_.sortBy(selectedAreaIds), _.sortBy($scope.selectedAreaIds))) {

                            let selectedAreas = $scope.areas.filter(area => selectedAreaIds.includes(area.id));
                            $scope.selectedAreaIds = selectedAreaIds;

                            areaService.setSelectedAreas(selectedAreas);
                            $rootScope.$broadcast('selectedAreasChanged', selectedAreas);
                        }
                    })
            }
        }

        function cancelOrder() {
            if (cancelOrderConfirmation()) {
                if (!$state.params.callOrder) {
                    ordersService.updateOrderStatus($state.params.orderId, orderStatusTransitions.cancel).then(function () {
                        cancelOrderConfirmed();
                    }, function () {
                        // error occurred
                    });
                } else {
                    cancelOrderConfirmed();
                }
            }
        }

        function toggleCompleted() {
            if ($state && $state.params.orderId) {

                if ($state.current.name === 'main.completedRouteStops') {
                    $state.go('main.routeStops', {
                        orderId: $state.params.orderId,
                        sortBy: routeStopsService.getSortBy(),
                        splitView: routeStopsService.getSplitViewStatus(),
                        status: 'uncompleted'
                    });
                } else {
                    $state.go('main.completedRouteStops', { orderId: $state.params.orderId });
                }

                commonUtil.hideModal();
            }
        }

        function back() {
            if ($state.current.name === 'main.routeStops' || ($state.current.name === 'main.map' && $scope.prevStateName === 'main.routeStops')) {
                if (cancelOrderConfirmation()) {
                    $rootScope.$broadcast('showBusyIndicator');

                    ordersService.updateOrderStatus($state.params.orderId, orderStatusTransitions.cancel).then(function () {
                        $state.go('main.orders');
                    }, function () {
                        // error occurred
                    });
                }
            } else if ($state.current.name === 'main.map') {
                $rootScope.$broadcast('showBusyIndicator');

                if ($scope.prevStateName) {
                    if ($scope.prevStateName === 'main.completedRouteStops') {
                        $state.go($scope.prevStateName, { orderId: $scope.prevParams.orderId });
                    } else {
                        $state.go($scope.prevStateName,
                            {
                                orderId: $scope.prevParams.orderId,
                                sortBy: routeStopsService.getSortBy(),
                                splitView: routeStopsService.getSplitViewStatus(),
                                status: $scope.prevParams.status
                            });
                    }
                } else {
                    $state.go('main.orders');
                }
            } else if ($state.current.name === 'main.deviation') {
                $rootScope.$broadcast('showBusyIndicator');

                if ($scope.prevStateName) {
                    if ($scope.prevStateName === 'main.completedRouteStops') {
                        $state.go($scope.prevStateName, { orderId: $scope.prevParams.orderId });
                    } else {
                        $state.go($scope.prevStateName,
                            {
                                orderId: $scope.prevParams.orderId,
                                sortBy: routeStopsService.getSortBy(),
                                splitView: routeStopsService.getSplitViewStatus(),
                                status: $scope.prevParams.status
                            });
                    }
                } else {
                    $state.go('main.routeStops',
                        {
                            orderId: $state.params.orderId,
                            sortBy: routeStopsService.getSortBy(),
                            splitView: routeStopsService.getSplitViewStatus(),
                            status: 'uncompleted'
                        });
                }
            } else if ($state.current.name === 'main.completedRouteStops') {
                $rootScope.$broadcast('showBusyIndicator');

                $state.go('main.routeStops',
                    {
                        orderId: $state.params.orderId,
                        sortBy: routeStopsService.getSortBy(),
                        splitView: routeStopsService.getSplitViewStatus(),
                        status: 'uncompleted'
                    });
            } else if ($state.current.name === 'main.settings') {
                $rootScope.$broadcast('showBusyIndicator');
            }
        }

        function support() {
            $kWindow.open({
                options: {
                    modal: true,
                    title: translateService.translate('navbar.support'),
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    height: 150,
                    width: 350
                },
                templateUrl: 'app/support/support-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'SupportController'
            });
        }

        function settings() {
            $kWindow.open({
                options: {
                    modal: true,
                    title: translateService.translate('navbar.settings'),
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    height: 150,
                    width: 350
                },
                templateUrl: 'app/settings/settings-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'SettingsController'
            });
        }

        //show nearby routestops in a popup
        function showNearbyRouteStops() {
            cacheService.get(routeStopsCachePrefix + $state.params.orderId).then(function (route) {

                addedNearbyRouteStops = _.filter(route.routeStops, { 'isNearbyRouteStop': true });

                var nearbyRouteStopsWindow = $kWindow.open({
                    options: {
                        modal: true,
                        title: translateService.translate('navbar.nearByRouteStopRegistration'),
                        draggable: false,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 600
                    },
                    templateUrl: 'app/nearbyRouteStops/nearby-route-stops.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'NearbyRouteStopsController',
                    resolve: {
                        addedRouteStops: function () {
                            return addedNearbyRouteStops;
                        },
                        orderId: function () {
                            return $state.params.orderId;
                        }

                    }
                });

                nearbyRouteStopsWindow.result.then(routeStop => {
                    if (routeStop) {
                        $scope.showInrouteModal = false;
                        addedNearbyRouteStops.push(routeStop);
                        $rootScope.$broadcast("routeStopAddRequest", routeStop);
                    }
                });
            });
        }

        function callOrders() {
            callOrderWindowInstance = $kWindow.open({
                options: {
                    modal: true,
                    title: translateService.translate('navbar.callOrder'),
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    height: 400,
                    width: 600
                },
                templateUrl: 'app/callOrders/call-orders-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'callOrdersController'
            });

            callOrderWindowInstance.result
                .then(() => callOrdersService.getCallOrders(true))
                .then(callOrders => {
                    $rootScope.$applyAsync(() => {
                        $rootScope.callOrderBadgeCount = callOrders
                            && callOrders.filter(o => !o.relatedOrderId).length
                            || 0;
                    });
                })
                .finally(() => $rootScope.overlayDisabled = false);
        }

        function messages() {
            messageWindowInstance = $kWindow.open({
                options: {
                    modal: true,
                    title: translateService.translate('navbar.messages'),
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    height: 400,
                    width: 600
                },
                templateUrl: 'app/messages/messages-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'MessagesController',
                resolve: {
                    routeStop: () => {
                        return false;
                    }
                }
            });
        }

        //Private functions
        function cancelOrderConfirmation() {
            $rootScope.cancelAlreadyConfirmed = confirm(translateService.translate('navbar.cancelRouteConfirmation')); 

            return $rootScope.cancelAlreadyConfirmed;
        }

        function cancelOrderConfirmed() {
            $state.go('main.orders');

            commonUtil.hideModal();
        }

        function preventPropagation(evt) {
            if (evt) {
                evt.stopPropagation();
                evt.preventDefault();
            }
        }
    }
})();
