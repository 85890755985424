(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('updateQueueUtils', updateQueueUtils);

    updateQueueUtils.$inject = [];

    function updateQueueUtils() {
        const utils = {
            getGroupedUpdateActionsFromQueue
        };

        function getGroupedUpdateActionsFromQueue(queue) {
            if (!queue?.length) return {};

            const groupedUpdateActions = _.groupBy(queue, 'type');

            return groupedUpdateActions;

        }

        return utils;
    }
})();
