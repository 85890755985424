(function () {
    'use strict';

    angular.module('PWAPoCApp').controller('AreaSelectionModalController', areaSelectionModalController);

    areaSelectionModalController.$inject = [
        '$scope',
        '$windowInstance',
        'areas',
        'selectedAreaIds',
        'translateService'
    ];

    function areaSelectionModalController(
        $scope,
        $windowInstance,
        areas,
        selectedAreaIds,
        translateService
    ) {
        $scope.areas = _.clone(areas);
        $scope.selectedAreaIds = _.clone(selectedAreaIds);

        $scope.isAreaSelected = isAreaSelected;
        $scope.toggleAreaSelection = toggleAreaSelection;
        $scope.translate = translateService.translate;

        $scope.ok = ok;
        $scope.close = close;

        function isAreaSelected(area) {
            return _.includes($scope.selectedAreaIds, area.id);
        }

        function toggleAreaSelection(area) {
            if (isAreaSelected(area)) {
                _.pull($scope.selectedAreaIds, area.id);
            } else {
                $scope.selectedAreaIds.push(area.id);
            }
        }

        function ok() {
            $windowInstance.close($scope.selectedAreaIds);
        }

        function close() {
            $windowInstance.close();
        }
    }
})();
