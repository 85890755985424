(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('cacheServiceUtils', cacheServiceUtils);

    cacheServiceUtils.$inject = ['$localForage'];

    function cacheServiceUtils($localForage) {
        const utils = {
            filterCacheKeysByUsername,
            deleteMultipleKeys,
            renameKey
        };

        function filterCacheKeysByUsername(keys, username) {
            const lowerCaseUsername = username.toLowerCase();
            return keys.filter(function (key) {
                const username = key.split('_')[0];
                return username.toLowerCase() === lowerCaseUsername;
            });
        }

        function deleteMultipleKeys(keys) {
            const promises = keys.map(function (key) {
                return $localForage.removeItem(key);
            });
            return Promise.all(promises);
        }

        function renameKey(key, newName) {
            return new Promise(function (resolve, reject) {
                if (key === newName) {
                    resolve();
                    return;
                }
                $localForage.getItem(key)
                .then(function (value) {
                    return $localForage.setItem(newName, value);
                })
                .then(function () {
                    return $localForage.removeItem(key);
                })
                .then(function () {
                    resolve();
                })
                .catch(function () {
                    reject();
                });
            });
        }

        return utils;
    }
})();
