(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('authServiceUtils', authServiceUtils);

    authServiceUtils.$inject = [];

    function authServiceUtils() {
        const utils = {
            getUsernameVariantsByCacheKey,
            getCachedUsernameToUse
        };

        function getUsernameVariantsByCacheKey(ownKeys) {
            return ownKeys.reduce(function (acc, key) {
                const name = key.split('_')[0];
                if (acc.indexOf(name) === -1) {
                    acc.push(name);
                }
                return acc;
            }, []);
        }

        function getCachedUsernameToUse(nameVariants, username) {
            const lowerCaseUsername = username.toLowerCase();
            const isCurrentExisting = nameVariants.includes(username);
            const isCorrectExisting = nameVariants.includes(lowerCaseUsername);

            return isCurrentExisting ? username : isCorrectExisting ? lowerCaseUsername : nameVariants[0];
        }

        return utils;
    }
})();
