(function () {
    'use strict';

    const pwaPoCApp = angular.module('PWAPoCApp');

    const settingKeys = {
        'AccuracyTreshold': 'AccuracyTreshold',
        'AutoModeToggleButtonVisible': 'AutoModeToggleButtonVisible',
        'BTAdapterName': 'BTAdapterName',
        'BTPrinterName': 'BTPrinterName',
        'CallOrderCheckInterval': 'CallOrderCheckInterval',
        'CleanOrderDataOlderThanInDays': 'CleanOrderDataOlderThanInDays',
        'DataButton1ProfileTypes': 'DataButton1ProfileTypes',
        'DataButton2ProfileTypes': 'DataButton2ProfileTypes',
        'DataButton3ProfileTypes': 'DataButton3ProfileTypes',
        'DataButton4ProfileTypes': 'DataButton4ProfileTypes',
        'DataButton5ProfileTypes': 'DataButton5ProfileTypes',
        'DisableAnimation': 'DisableAnimation',
        'DisplayProfileType': 'DisplayProfileType',
        'DistributeExtraBagsOnSelectedContainers': 'DistributeExtraBagsOnSelectedContainers',
        'EnablePrefillOnDataButtonOne': 'EnablePrefillOnDataButtonOne',
        'EnablePrinter': 'EnablePrinter',
        'EnableSmsReceipt': 'EnableSmsReceipt',
        'EnableTracing': 'EnableTracing',
        'EnableWeight': 'EnableWeight',
        'Exception1ButtonVisible': 'Exception1ButtonVisible',
        'FavouriteDeviationIds': 'FavouriteDeviationIds',
        'IsAjour':'IsAjour',
        'IsDataButtonsEnabled': 'IsDataButtonsEnabled',
        'IsDeviationUnitsUnselected': 'IsDeviationUnitsUnselected',
        'IsDeviationUnitsUntoggled': 'IsDeviationUnitsUntoggled',
        'IsDeviationCommentConditionalRequired': 'IsDeviationCommentConditionalRequired',
        'IsDeviationPictureConditionalRequired': 'IsDeviationPictureConditionalRequired',
        'IsOrderTypeIconVisible': 'IsOrderTypeIconVisible',
        'IsServiceUser': 'IsServiceUser',
        'IsUndergroundUser': 'IsUndergroundUser',
        'LoadProfileInfoData': 'LoadProfileInfoData',
        'NoButtonRequiresDeviation': 'NoButtonRequiresDeviation',
        'QueueItemProcessCount': 'QueueItemProcessCount',
        'QRCodeRegex': 'QRCodeRegex',
        'SmsSenderId': 'SmsSenderId',
        'SmsSenderName': 'SmsSenderName',
        'ValidationDataButton1RequiredForProfilType': 'ValidationDataButton1RequiredForProfilType',
        'ValidationDataButton1RequiredOrDeviation': 'ValidationDataButton1RequiredOrDeviation',
        'ValidationDataButton2RequiredForProfilType': 'ValidationDataButton2RequiredForProfilType',
        'ValidationDataButton2RequiredOrDeviation': 'ValidationDataButton2RequiredOrDeviation',
        'ValidationDataButton3RequiredForProfilType': 'ValidationDataButton3RequiredForProfilType',
        'ValidationDataButton3RequiredOrDeviation': 'ValidationDataButton3RequiredOrDeviation',
        'ValidationDataButton4RequiredForProfilType': 'ValidationDataButton4RequiredForProfilType',
        'ValidationDataButton4RequiredOrDeviation': 'ValidationDataButton4RequiredOrDeviation',
        'ValidationDataButton5RequiredForProfilType':'ValidationDataButton5RequiredForProfilType',
        'ValidationDataButton5RequiredOrDeviation': 'ValidationDataButton5RequiredOrDeviation',
        'VoltAdjustUrl': 'VoltAdjustUrl',
        'WalkingDistanceUnitIds': 'WalkingDistanceUnitIds',
        'WeightUnit': 'WeightUnit',
        'WMSUrl': 'WMSUrl',
        'WMSLayers': 'WMSLayers'
    };

    const settingMetadataSource = {
        vehicle: 'vehicle',
        user: 'user'
    };

    const settingMetadataType = {
        number: 'number',
        boolean: 'boolean',
        string: 'string'
    };

    pwaPoCApp.constant('settingKeys', settingKeys);

    pwaPoCApp.constant('settingMetadataSource', settingMetadataSource);

    pwaPoCApp.constant('settingMetadataType', settingMetadataType);

    pwaPoCApp.constant('settingsMetadata', [
        {
            key: settingKeys.AccuracyTreshold,
            type: settingMetadataType.number,
            source: settingMetadataSource.vehicle,
            defaultValue: 11
        },
        {
            key: settingKeys.AutoModeToggleButtonVisible,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: true
        },
        {
            key: settingKeys.BTAdapterName,
            type: settingMetadataType.string,
            source: settingMetadataSource.vehicle,
            defaultValue: ''
        },
        {
            key: settingKeys.BTPrinterName,
            type: settingMetadataType.string,
            source: settingMetadataSource.vehicle,
            defaultValue: ''
        },
        {
            key: settingKeys.CallOrderCheckInterval,
            type: settingMetadataType.number,
            source: settingMetadataSource.vehicle,
            defaultValue: 30000
        },
        {
            key: settingKeys.CleanOrderDataOlderThanInDays,
            type: settingMetadataType.number,
            source: settingMetadataSource.vehicle,
            defaultValue: 5
        },
        {
            key: settingKeys.DataButton1ProfileTypes,
            type: settingMetadataType.string,
            source: settingMetadataSource.vehicle,
            defaultValue: ''
        },
        {
            key: settingKeys.DataButton2ProfileTypes,
            type: settingMetadataType.string,
            source: settingMetadataSource.vehicle,
            defaultValue: ''
        },
        {
            key: settingKeys.DataButton3ProfileTypes,
            type: settingMetadataType.string,
            source: settingMetadataSource.vehicle,
            defaultValue: ''
        },
        {
            key: settingKeys.DataButton4ProfileTypes,
            type: settingMetadataType.string,
            source: settingMetadataSource.vehicle,
            defaultValue: ''
        },
        {
            key: settingKeys.DataButton5ProfileTypes,
            type: settingMetadataType.string,
            source: settingMetadataSource.vehicle,
            defaultValue: ''
        },
        {
            key: settingKeys.DisableAnimation,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: false
        },
        {
            key: settingKeys.DisplayProfileType,
            type: settingMetadataType.number,
            source: settingMetadataSource.vehicle,
            defaultValue: 0
        },
        {
            key: settingKeys.DistributeExtraBagsOnSelectedContainers,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: false,
        },
        {
            key: settingKeys.EnablePrefillOnDataButtonOne,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: true
        },
        {
            key: settingKeys.EnablePrinter,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: false
        },
        {
            key: settingKeys.EnableSmsReceipt,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: false
        },
        {
            key: settingKeys.EnableTracing,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: false
        },
        {
            key: settingKeys.EnableWeight,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: false
        },
        {
            key: settingKeys.Exception1ButtonVisible,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: false
        },
        {
            key: settingKeys.FavouriteDeviationIds,
            type: settingMetadataType.string,
            source: settingMetadataSource.vehicle,
            defaultValue: ''
        },
        {
            key: settingKeys.IsAjour,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: false
        },
        {
            key: settingKeys.IsDataButtonsEnabled,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: true
        },
        {
            key: settingKeys.IsDeviationUnitsUnselected,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: false
        },
        {
            key: settingKeys.IsDeviationUnitsUntoggled,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: false
        },
        {
            key: settingKeys.IsDeviationCommentConditionalRequired,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: false
        },
        {
            key: settingKeys.IsDeviationPictureConditionalRequired,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: false
        },
        {
            key: settingKeys.IsOrderTypeIconVisible,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: false
        },
        {
            key: settingKeys.IsServiceUser,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: false
        },
        {
            key: settingKeys.IsUndergroundUser,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: false
        },
        {
            key: settingKeys.LoadProfileInfoData,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: false
        },
        {
            key: settingKeys.NoButtonRequiresDeviation,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: false
        },
        {
            key: settingKeys.QueueItemProcessCount,
            type: settingMetadataType.number,
            source: settingMetadataSource.vehicle,
            defaultValue: 1
        },
        {
            key: settingKeys.QRCodeRegex,
            type: settingMetadataType.string,
            source: settingMetadataSource.vehicle,
            defaultValue: ''
        },
        {
            key: settingKeys.SmsSenderId,
            type: settingMetadataType.string,
            source: settingMetadataSource.vehicle,
            defaultValue: ''
        },
        {
            key: settingKeys.SmsSenderName,
            type: settingMetadataType.string,
            source: settingMetadataSource.vehicle,
            defaultValue: ''
        },
        {
            key: settingKeys.ValidationDataButton1RequiredForProfilType,
            type: settingMetadataType.string,
            source: settingMetadataSource.vehicle,
            defaultValue: ''
        },
        {
            key: settingKeys.ValidationDataButton1RequiredOrDeviation,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: false
        },
        {
            key: settingKeys.ValidationDataButton2RequiredForProfilType,
            type: settingMetadataType.string,
            source: settingMetadataSource.vehicle,
            defaultValue: ''
        },
        {
            key: settingKeys.ValidationDataButton2RequiredOrDeviation,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: false
        },
        {
            key: settingKeys.ValidationDataButton3RequiredForProfilType,
            type: settingMetadataType.string,
            source: settingMetadataSource.vehicle,
            defaultValue: ''
        },
        {
            key: settingKeys.ValidationDataButton3RequiredOrDeviation,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: false
        },
        {
            key: settingKeys.ValidationDataButton4RequiredForProfilType,
            type: settingMetadataType.string,
            source: settingMetadataSource.vehicle,
            defaultValue: ''
        },
        {
            key: settingKeys.ValidationDataButton4RequiredOrDeviation,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: false
        },
        {
            key: settingKeys.ValidationDataButton5RequiredForProfilType,
            type: settingMetadataType.string,
            source: settingMetadataSource.vehicle,
            defaultValue: ''
        },
        {
            key: settingKeys.ValidationDataButton5RequiredOrDeviation,
            type: settingMetadataType.boolean,
            source: settingMetadataSource.vehicle,
            defaultValue: false
        },
        {
            key: settingKeys.VoltAdjustUrl,
            type: settingMetadataType.string,
            source: settingMetadataSource.vehicle,
            defaultValue: ''
        },
        {
            key: settingKeys.WalkingDistanceUnitIds,
            type: settingMetadataType.string,
            source: settingMetadataSource.vehicle,
            defaultValue: ''
        },
        {
            key: settingKeys.WeightUnit,
            type: settingMetadataType.string,
            source: settingMetadataSource.vehicle,
            defaultValue: ''
        },
        {
            key: settingKeys.WMSUrl,
            type: settingMetadataType.string,
            source: settingMetadataSource.vehicle,
            defaultValue: ''
        },
        {
            key: settingKeys.WMSLayers,
            type: settingMetadataType.string,
            source: settingMetadataSource.vehicle,
            defaultValue: ''
        },

    ]);
})();
